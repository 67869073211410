
import React, { Component } from 'react';
import { QuestionCircleOutlined } from "@ant-design/icons";

class error extends Component {
  render() {
    return (
        <body className='error'>
        <div className='errormainbox'>
        <div class="errorerr">404
         
        
        </div>
       
        <div className='errormsg'>Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first place?<p>Let's go <a href={process.env.REACT_APP_BASE_URL}>home</a> and try from there.</p></div>
          </div>
          </body>
    )
  }
}

export default error;
