import React, { Component } from 'react';
import '../styles/common.css';
// import '../styles/fontawesome-all.min.css';

export default class Sidebar2 extends Component {

    componentDidMount() {
        if(!localStorage.getItem('investor_id')) {
          window.location.assign('/Login');
          return;
        }
      }

  render() {
    return (
        
        <div className='investor-sidebar container'>
            <div className="row row-cols-4">
                <div className="col-md-12 col-2"><a href='/investor-dashboard' className={window.location.pathname=="/investor-dashboard"
                        ? "active" : "" }>
                        <li className='hiw-li row  text-center'>
                            <i className='bx bxs-pie-chart-alt-2 font-weight-500 ps-md-0 ps-4 col-md-4'></i>
                            <div className='col-md-4 col-12 side-text'>Dashboard</div>

                        </li>
                    </a>
                </div>
                <div className="col-md-12 col-2"><a 
                    href='/investor-portfolio'
                    className={window.location.pathname == "/investor-portfolio" ? "active" : ""}
                >
                    <li className='hiw-li row text-center'>
                    <i className='bx bxs-user-circle col-md-4 ps-md-0 ps-3'></i>
                    <div className='col-md-4 col-12 side-text'>Portfolio</div>
                    </li>
                </a></div>
                <div className="col-md-12 col-2"> <a 
                    href='/investor-analytics'
                    className={window.location.pathname == "/investor-analytics" ? "active" : ""}
                >
                    <li className='hiw-li row text-center'>
                    <i className='bx bx-signal-5 col-md-4 ps-md-0 ps-3  '></i> 
                    <div className='col-md-4 col-12 side-text'>Analytics</div>

                    </li>
                </a></div>
                <div className="col-md-12 col-2"> <a 
                    href='/investor-transactions'
                    className={window.location.pathname == "/investor-transactions" ? "active" : ""}
                >
                    <li className='hiw-li row text-center'>
                    {/* <img className='col-md-4 col-12 ' src='icon/transaction.png' style={{width : '50px'}} alt=""/> */}
                    {/* <i className='bi bi-cash-coin col-md-4' ></i> */}
                    <i className='bx bx-transfer col-md-4'/>
                    <div className='col-md-4 col-12 side-text'>Transactions</div>

                    </li>
                </a></div>
                <div className="col-md-12 col-2"> <a 
                    href='/Referral'
                    className={window.location.pathname == "/Referral" ? "active" : ""}
                >
                    <li className='hiw-li row text-center'>
                    {/* <img className='col-md-4 col-12 ' src='icon/transaction.png' style={{width : '50px'}} alt=""/> */}
                    {/* <i className='bi bi-cash-coin col-md-4' ></i> */}
                    <i className='bx bx-transfer-alt col-md-4'/>
                    <div className='col-md-4 col-12 side-text'>Referral</div>

                    </li>
                </a></div>
            </div>
        </div>
    )
  }
}
