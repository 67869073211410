import React, { Component } from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

class BottomBar extends Component {
  render() {
    return (
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        @2022 All rights are reserved. Created by Yasham software.
      </Footer>
    )
  }
}

export default BottomBar;