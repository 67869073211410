import React,{Component} from 'react'
import {Modal,message} from 'antd';
import Bridge from '../../constants/Bridge';

export default class InvestmentMembershipmodal extends Component {
    constructor(props){
        super(props);
        this.state={
            modalstatus:false,
            discounted_amount:0,
            amount:0,
            discount:0,
        }
    }
    componentDidMount(){
        this.getsettings();
    }
    getsettings = () => {
        this.setState({amountloader:true});
        Bridge.admin.settings.getsettings().then((result) => {
          if (result.status==1) {
            let amount=Number(result.data[0].amount/100)*Number(result.data[0].discount);
            amount=Number(result.data[0].amount)-Number(amount);
            this.setState({
                amount:result.data[0].amount,
                discount:result.data[0].discount,
                discounted_amount:amount,
            });
          }else{}
        });
    }
    showModal=()=>{
        if(this.props.investor_id){
            if(Number(this.props.amount) < Number(this.props.minamount)){
                message.warning('Minimum investment amount is '+this.props.minamount);
                return;
            }
            if(Number(this.props.amount) > Number(this.props.maxamount)){
                message.warning('Maximum investment amount is '+this.props.maxamount,5);
                return;
            }
            if(this.props.agree != true) {
                message.warning('Please agree to terms and conditions');
                return;
            }
            // if(this.props.membership_type=='regular'){
            //     this.setState({modalstatus:true});
            // }else{
                this.props.invest();
            // }
        }
    }
    handleOk=()=>{
        this.setState({modalstatus:false});
    }
    handleCancel=()=>{
        this.setState({modalstatus:false});this.props.invest()
    }
    upgrade=()=>{
        let order_id='order-01';
        let user_id=this.props.investor_id;
        let amount=this.state.discounted_amount;
        let membership_fees=this.state.discounted_amount;
        let registered_amt=this.state.amount;
        let deal_id=this.props.deal_id;
        let url=`${process.env.REACT_APP_BASE_URL}cashfree/membership/investment/checkout.php?user_id=${user_id}&order_id=${order_id}&amount=${amount}&membership_fees=${membership_fees}&registered_amt=${registered_amt}&deal_id=${deal_id}`;
        window.location.assign(url);
    }
  render() {
    return (
      <div>
        <button 
            type="button" 
            className="login-button prime-bg"  style={{width:"100%"}}
            onClick={this.showModal}
        >
            Invest Now
        </button>
        <Modal 
            title={false}
            visible={this.state.modalstatus} 
            onOk={this.handleOk} 
            onCancel={this.handleCancel}
            okText={'Upgrade'}
            cancelText={'Cancel'}
            header={false}
            footer={false}
        >
            <div className='membership-modal'>
                <center>
                    <img 
                        src={'./membership-top-img.png'} 
                        style={{
                            marginTop:20,
                            marginBottom:20,
                            maxWidth:276,
                        }}
                    />
                </center>
                <p>
                    Upgrade to Premium Membership to get {this.state.discount}% <br/>off for the all deals
                    <br/>You have to pay <strike>₹{this.state.amount}</strike> 
                    &nbsp; ₹{this.state.discounted_amount} only.
                </p>
                <div className='membership-buttons'>
                    <a href='#' onClick={this.handleCancel} className='cancel'>Skip Now</a>
                    <a href='#' onClick={this.upgrade} className='upgrade'>Upgrade</a>
                </div>
            </div>
        </Modal>
      </div>
    )
  }
}
