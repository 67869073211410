
import React, { Component } from 'react';
import WebHeader from './common/WebHeader';
import WebFooter from './common/WebFooter';
import { Select, Tabs, message, Spin,Tooltip   } from 'antd';
import {  InfoCircleOutlined,CheckCircleFilled } from '@ant-design/icons'; 
import Bridge from './constants/Bridge';
import Apis from './constants/Apis';
import { Link } from 'react-router-dom';
import moment from 'moment';

const { Option } = Select;
const { TabPane } = Tabs;           

class Deals extends Component {

  constructor(props) {
    super(props);
    this.state = {       
      sort_by:'',
      searchInput:'',
      deals:[],
      cdeals:[],
      loading:false,
      todaydate:'',
      remaining_days:0,
      membership_type:'regular',
      investor_id:0,
    }
  }
  componentDidMount() {
    // console.log('invetor_id',localStorage.getItem('investor_id'));
    if(localStorage.getItem('investor_id')){
      this.setState({
        investor_id:localStorage.getItem('investor_id')
      },()=>this.check_for_membership_type());
    }else if(localStorage.getItem('founder_id')){
      this.get_founder_details();
    } else {
      this.check_for_membership_type();
    }
  }
  get_founder_details=()=>{
    let params={
      founder_id:localStorage.getItem('founder_id'),
    }
    Bridge.founder.get_founder_profile_details(params).then((result)=>{
      if(result.status=='1'){
        if(result.data.length>0){
          let investor_id = localStorage.getItem('founder_id');
          this.setState({investor_id:investor_id});
          setTimeout(()=>{
            if(result.data[0].is_investor=='1'){
              this.setState({founder_is_investor:'1'},()=>this.check_for_membership_type());
            }else{
              this.setState({founder_is_investor:'0'},()=>this.check_for_membership_type());
            }
          },200);
        }
      }
      else{
      }
    });
  }
  check_for_membership_type=()=>{
    this.setState({formloader:true});
    if(this.state.investor_id){
      let params={
        investor_id:this.state.investor_id
      }
      Bridge.check_for_membership_type(params).then((result) => {
        if(result.status=='1'){
          if(result.data.length>0){
            this.setState({membership_type:result.data[0].membership_type},()=>this.getDeals());
          } else {
            this.setState({formloader:false});
          }
        }
      });
    } else{
      this.getDeals();
    }
  }
  getDifferenceInDays=(date1, date2)=> {
   let diff= Math.floor((Date.parse(date2) - Date.parse(date1)) / 86400000);
   let final=0;
   if(diff<0){
    final=0;
   }else{
    final=diff;
   }
    return final
  }
  // get deal list
  getDeals = () => {
    this.setState({ loading: true });
    Bridge.deal.list().then((result) => {
      if (result.status == 1) {
        let arr=[];
        let investor_id=this.state.investor_id;
        this.setState({todaydate: moment().format('YYYY-MM-DD')})
        for(let item of result.data){
          if(item.show_status=='1'){
            if(item.deal_type=='Private'){
              if(investor_id && item.invitations.length>0 && item.invitations.includes(investor_id)){
                arr=[...arr,item];  
              }
            }else{
              arr=[...arr,item];
            }
          }
        }
        let list=[];
        let current_date=moment();
        for(let item of arr){
          let deal_regular_show_date=moment(item.regular_show_date);
          let deal_premium_show_date=moment(item.premium_show_date);
          
          if(this.state.membership_type=='premium'){
            if(moment(current_date).format('YYYY-MM-DD')==moment(deal_premium_show_date).format('YYYY-MM-DD')){
              list=[...list,item];    
            } else if(current_date>deal_premium_show_date){
              list=[...list,item];
            }
          }else {
            if(moment(current_date).format('YYYY-MM-DD')==moment(deal_regular_show_date).format('YYYY-MM-DD')){
              list=[...list,item];    
            } else if(current_date>deal_regular_show_date){
              list=[...list,item];
            }
          }
        }
        this.setState({
          deals:list,
          cdeals:list,
          loading:false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  handleChangeSortBy = (value) => {
    this.setState({
      sort_by: value,
    });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value 
    });
    if(!e.target.value){
      this.setState({deals:this.state.cdeals});
    }
  }

  // handleChange = (e) => {
  //   let text = e.target.value;
  //   this.setState({ loading:true,handleChange:text });
  //   if(text) {
  //     let arr = [];
  //     for(let item of this.state.cstartups) {
  //       if(
  //         item.name && item.name.toLowerCase().includes(text.toLowerCase()) ||
  //         item.status && item.status.toLowerCase().includes(text.toLowerCase()) ||
  //         item.startupid && item.startupid.includes(text.toLowerCase())
  //       ) {
  //         arr = [...arr, item];
  //       }
  //     }
  //     this.setState({
  //       startups: arr,
  //       loading:false,
  //     });
  //   } else {
  //     this.setState({
  //       startups: this.state.cstartups,
  //       loading:false,
  //     });
  //   }
  // }


  openpage = (item) => {
    localStorage.setItem('deal_id', item.deal_id);
    window.open('/DealDetails','_self');
  }

  searchdeals = () => {
    let deals = this.state.cdeals;
    let searchInput = this.state.searchInput;
    if(searchInput){
      this.setState({ loading:false });
      deals = deals.filter((deal)=>{
        return deal.name.toLowerCase().includes(searchInput.toLowerCase());
      });
    }
    this.setState({
      deals: deals,
      loading: false,
    });
  }
  sortdata = (value) => {
    let sortby = value;
    let deals = this.state.deals;
    if(sortby=='asc') {
        deals.sort((a,b) => {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
    } else if(sortby=='desc') {
      deals.sort((a,b) => {
            return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
        });
    } else if(sortby=='dateasc') {
        deals.sort((a,b) => {
            return a.created_at > b.created_at ? 1 : -1;
        });
    } else if(sortby=='datedesc') {
        deals.sort((a,b) => {
            return a.created_at < b.created_at ? 1 : -1;
        });
    } else if(sortby =='newest') {
        deals.sort((a,b) => {
            return a.created_at > b.created_at ? 1 : -1;
        });
    } else if(sortby =='hightolow') {
        deals.sort((a,b) => {
            return a.min > b.min ? 1 : -1;
        });
    } else if(sortby =='lowtohigh') {
        deals.sort((a,b) => {
            return a.Investment_amt < b.Investment_amt ? 1 : -1;
        });
    } else if(sortby =='oldest') {
        deals.sort((a,b) => {
            return a.created_at < b.created_at ? 1 : -1;
        });
    }
    this.setState({
      deals: deals,
      sort_by: sortby,
    });
}


  render() {
    return (
      <div className='deals-page' style={{
        marginTop:171
      }}>
        <WebHeader />
        <div>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='main-header-section'>
                  {/* <h1>Live Deals</h1> */}
                  <p>
                    Browse current investment opportunities on Growth91.
                  </p>
                </div>
              </div>
            </div>
           
          </div>
          <div className="container">
            <div className="row align-items-center"
            style={{
              marginBottom: 30,
              marginTop: 40,
            }}>
              {/* <div className="col-lg-9">
                <div className="search-container">
                  <input 
                    type="text" 
                    placeholder="Search.." 
                    name="searchInput"
                    onChange={this.handleChange}
                  />
                  <button onClick={this.searchdeals}>
                    <i className='bx bx-search'></i>
                  </button>
                </div>
                
              </div> */}
              {/* <div className="col-lg-3">
                <Select 
                  value={this.state.sort_by} 
                  style={{ width: '100%' }} 
                  onChange={(e) => this.sortdata(e)}
                >
                  <Option value=''>Sort By</Option>
                  <Option value='newest'>Newest</Option>
                  <Option value='oldest'>Oldest</Option>
                  <Option value='hightolow'>Min. Investment High to Low</Option>
                  <Option value='lowtohigh'>Min. Investment Low to High</Option>
                </Select>
              </div> */}
            </div>
        {/* <hr style={{height:"5px",backgroundColor:"black"}}/> */}
          </div>
        </div>
        {this.state.deals.length==0 && (
          <p
          style={{
            textAlign: 'center',
            fontSize: 19,
            marginBottom: 55,
            marginTop: 60,
          }}  
          >Exciting Deals Coming Soon. Stay Tuned. </p>
        )}
        {this.state.deals.length>0 && (
        <Spin spinning={this.state.loading}>
          <div className='tab-wrapper'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  
                  <Tabs defaultActiveKey="1" centered>
                  <TabPane tab="All" key="1">
                    <h1  style={{ marginBottom:'20px',fontSize:'2rem' }}>Live Deals</h1>
                      <div className='row deal-list'>
                      </div>
                      <div className='row deal-list'>

                        {/* static */}

                        {/* dynamic */}
                        
                        {this.state.deals && this.state.deals.map((item, index) => {
                          let logourl = Apis.IMAGEURL+'deal/logo/'+item.deal_id+'/'+item.logo;
                          let bannerurl = Apis.IMAGEURL+'deal/banner/'+item.deal_id+'/'+item.banner_img;
                          {/* console.log('deal',item.deal_id+''+item.name); */}
                          let categories = JSON.parse(item.deal_category);
                          // item.deal_status = 'Closed';
                          if(item.deal_status!='Closed' && item.show_status==1){
                          return (
                            <div className='col-lg-4' key={index}>
                              <a 
                                href={item.page_link}
                                // onClick={()=>this.openpage(item)}
                              >
                                <div className='deal-item'>
                                  <div className='d-flex tag-list'>
                                    {item.deal_type=='Private' && (
                                      <div className='private-tag'>
                                        <span style={{fontSize:12}}>Private</span> &nbsp;&nbsp;
                                        {/* <InfoCircleOutlined style={{marginTop:-2}}/> */}
                                      </div>
                                    )}
                                  </div>
                                  <div className='deal-item-img'>
                                    <img src={bannerurl}
                                    className='deal-cover-img'
                                    alt='deal-img' />
                                  </div>
                                  <div className='deal-item-info'>
                                    <div className='row align-items-center'>
                                      <div className='col-8 col-lg-8'>
                                        <div className='d-flex inner align-items-center'>
                                            <img src={logourl} alt='deal-img' className='deal-logo' />
                                            <h6>{item.deal_name}</h6>
                                        </div>
                                      </div>
                                      {/* - {item.deal_id} */}
                                      <div className='col-4 col-lg-4'>
                                        <Tooltip title=" Compulsorily Convertible Debentures (CCD), with same financial rights as equity shares as on fully diluted basis.  There are no voting rights or cap table entry unless converted in future. ">
                                          <div className='hero-tag'
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            fontSize:11,
                                            maxWidth: 81,
                                          }}      >
                                            {item.deal_service}  &nbsp;<InfoCircleOutlined />
                                          </div>
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <p style={{textAlign: "justify"}}>
                                        {item.Muliples_of.length > 100 ? item.Muliples_of.substring(0, 100) + '...' : item.Muliples_of}
                                    </p>
                                      <div className='d-flex raised-txt justify-content-between'>
                                        <div>Percent Raised <br/><p className='per'>{parseFloat((((item.total_invested_amount)/(item.deal_fund_requested))*100)+parseInt(item.raiegap)).toFixed(0)}%</p></div>
                                        {(this.getDifferenceInDays(this.state.todaydate, moment(item.deal_end_date).format('YYYY-MM-DD'))==0)?"":(<div>Closes in <br/><p className='per'>{(this.getDifferenceInDays(this.state.todaydate, moment(item.deal_end_date).format('YYYY-MM-DD'))==0)?" ":this.getDifferenceInDays(this.state.todaydate, moment(item.deal_end_date).format('YYYY-MM-DD'))} Days</p></div>)}
                                      </div>
                                    <div className='d-flex tags'>
                                      {categories.length > 0 && categories.map((cat, index) => {
                                        return(
                                          <div className='hero-tag' key={index}>{cat}</div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          )}
                        })}
                      </div>
                    </TabPane>
                    <TabPane tab="Public" key="2">
                    <h1 style={{ marginBottom:'20px',fontSize:'2rem'}}>Live Deals</h1>
                      <div className='row deal-list'>

                      {this.state.deals && this.state.deals.map((item, index) => {
                          let logourl = Apis.IMAGEURL+'deal/logo/'+item.deal_id+'/'+item.logo;
                          let bannerurl = Apis.IMAGEURL+'deal/banner/'+item.deal_id+'/'+item.banner_img;
                          
                          let categories = JSON.parse(item.deal_category);
                          if(item.deal_type=='Public' && item.deal_status!='Closed' && item.show_status==1){
                          return (
                            <div className='col-lg-4' key={index}>
                              <a 
                               href={item.page_link}
                                // onClick={() => this.openpage(item)}
                              >
                                <div className='deal-item'>

                                  <div className='d-flex tag-list'>
                                    {item.deal_type=='Private' && (
                                      <div className='private-tag'>
                                        <span style={{ fontSize:12 }}>Private</span> &nbsp;&nbsp;
                                        {/* <InfoCircleOutlined style={{
                                          marginTop: -2,
                                        }}/> */}
                                      </div>
                                    )}
                                    {/* <div className='new-tag'>
                                      <span style={{ fontSize:12 }}>New</span>
                                    </div>
                                    <div className='funded-tag'>
                                      <CheckCircleFilled style={{
                                        position: 'relative',
                                        top: -2,
                                      }}/> &nbsp;&nbsp;
                                      <span style={{ fontSize:12 }}>Funded</span>
                                    </div>      */}
                                  </div>
                                  <div className='deal-item-img'>
                                    <img src={bannerurl}
                                    className='deal-cover-img'
                                    alt='deal-img' />
                                  </div>
                                  <div className='deal-item-info'>
                                    <div className='row align-items-center'>
                                      <div className='col-8 col-lg-8'>
                                        <div className='d-flex inner align-items-center'>
                                            <img src={logourl} alt='deal-img' className='deal-logo' />
                                            <h6>{item.name}</h6>
                                        </div>
                                      </div>
                                      <div className='col-4 col-lg-4'>
                                        <Tooltip title={item.deal_tooltip}>
                                          <div className='hero-tag'
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            fontSize:11,
                                            maxWidth: 81,
                                          }}      >
                                            {item.deal_service}  &nbsp;<InfoCircleOutlined />
                                          </div>
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <p>
                                        {item.Muliples_of.length > 100 ? item.Muliples_of.substring(0, 100) + '...' : item.Muliples_of}
                                    </p>
                                      <div className='d-flex raised-txt justify-content-between'>
                                      <div>Percent Raised <br/><p className='per'>{Math.floor((((item.total_invested_amount)/(item.deal_fund_requested))*100)+parseInt(item.raiegap)).toFixed(0)} %</p></div>
                                        <div>Closes in <br/><p className='per'>{this.getDifferenceInDays(this.state.todaydate, moment(item.deal_end_date).format('YYYY-MM-DD'))} Days</p></div>
                                      </div>
                                    <div className='d-flex tags'>
                                      {categories.length > 0 && categories.map((cat, index) => {
                                        return(
                                          <div className='hero-tag' key={index}>{cat}</div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          )
                          }
                        })}
                        </div>
                    </TabPane>
                    <TabPane tab="Private" key="3">
                    <h1 style={{ marginBottom:'20px',fontSize:'2rem' }}>Live Deals</h1>
                      <div className='row deal-list'>

                      {this.state.deals && this.state.deals.map((item, index) => {
                          let logourl = Apis.IMAGEURL+'deal/logo/'+item.deal_id+'/'+item.logo;
                          let bannerurl = Apis.IMAGEURL+'deal/banner/'+item.deal_id+'/'+item.banner_img;
                          
                          let categories = JSON.parse(item.deal_category);
                          if(item.deal_type=='Private' && item.show_status==1){
                          return (
                            <div className='col-lg-4' key={index}>
                              <a 
                               href={item.page_link}
                                // onClick={() => this.openpage(item)}
                              >
                                <div className='deal-item'>

                                  <div className='d-flex tag-list'>
                                    {item.deal_type=='Private' && (
                                      <div className='private-tag'>
                                        <span style={{ fontSize:12 }}>Private</span> &nbsp;&nbsp;
                                        {/* <InfoCircleOutlined style={{
                                          marginTop: -2,
                                        }}/> */}
                                      </div>
                                    )}
                                    {/* <div className='new-tag'>
                                      <span style={{ fontSize:12 }}>New</span>
                                    </div>
                                    <div className='funded-tag'>
                                      <CheckCircleFilled style={{
                                        position: 'relative',
                                        top: -2,
                                      }}/> &nbsp;&nbsp;
                                      <span style={{ fontSize:12 }}>Funded</span>
                                    </div>      */}
                                  </div>
                                  <div className='deal-item-img'>
                                    <img src={bannerurl}
                                    className='deal-cover-img'
                                    alt='deal-img' />
                                  </div>
                                  <div className='deal-item-info'>
                                    <div className='row align-items-center'>
                                      <div className='col-8 col-lg-8'>
                                        <div className='d-flex inner align-items-center'>
                                            <img src={logourl} alt='deal-img' className='deal-logo' />
                                            <h6>{item.name}</h6>
                                        </div>
                                      </div>
                                      <div className='col-4 col-lg-4'>
                                        <Tooltip title={item.deal_tooltip}>
                                          <div className='hero-tag'
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            fontSize:11,
                                            maxWidth: 81,
                                          }}      >
                                            {item.deal_service} &nbsp; <InfoCircleOutlined />
                                          </div>
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <p>
                                        {item.Muliples_of.length > 100 ? item.Muliples_of.substring(0, 100) + '...' : item.Muliples_of}
                                    </p>
                                      <div className='d-flex raised-txt justify-content-between'>
                                      <div>Percent Raised <br/><p className='per'>{Math.floor((((item.total_invested_amount)/(item.deal_fund_requested))*100)+parseInt(item.raiegap)).toFixed(0)} %</p></div>
                                        <div>Closes in <br/><p className='per'>{this.getDifferenceInDays(this.state.todaydate, moment(item.deal_end_date).format('YYYY-MM-DD'))} Days</p></div>
                                      </div>
                                    <div className='d-flex tags'>
                                      {categories.length > 0 && categories.map((cat, index) => {
                                        return(
                                          <div className='hero-tag' key={index}>{cat}</div>
                                        )
                                      })}
                                    </div>
                                    
                                  </div>
                                </div>
                              </a>
                            </div>
                          )
                          }
                        })}

                      </div>
                    </TabPane>
                  </Tabs>
                  
                </div>
              </div>
            </div>
          </div>
          </Spin>
        )}
        {this.state.deals.length>0 && (
          <div className='container' style={{ marginBottom:60,marginTop:100 }}>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='main-header-section'>
                <hr style={{height:"5px",backgroundColor:"black",fontSize:'2rem'}}/>
                  <h1>Closed Deals</h1>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.deals.length>0 && (  
          <Spin spinning={this.state.loading}>
          <div className='container'>
            <div className='row deal-list'>

              {this.state.deals && this.state.deals.map((item, index) => {
                let logourl = Apis.IMAGEURL+'deal/logo/'+item.deal_id+'/'+item.logo;
                let bannerurl = Apis.IMAGEURL+'deal/banner/'+item.deal_id+'/'+item.banner_img;
                
                let categories = JSON.parse(item.deal_category);
                if(item.deal_status=='Closed'){
                return (
                  <div className='col-lg-4' key={index}>
                    <a 
                      href={item.page_link}
                      // onClick={() => this.openpage(item)}
                    >
                      <div className='deal-item'>

                        <div className='d-flex tag-list'>
                          {item.deal_type=='Private' && (
                            <div className='private-tag'>
                              <span style={{ fontSize:12 }}>Private</span> &nbsp;&nbsp;
                              {/* <InfoCircleOutlined style={{
                                marginTop: -2,
                              }}/> */}
                            </div>
                          )}  
                        </div>
                        <div className='deal-item-img'>
                          <img src={bannerurl}
                          className='deal-cover-img'
                          alt='deal-img' />
                        </div>
                        <div className='deal-item-info'>
                          <div className='row align-items-center'>
                            <div className='col-8 col-lg-8'>
                              <div className='d-flex inner align-items-center'>
                                  <img src={logourl} alt='deal-img' className='deal-logo' />
                                  <h6>{item.name}</h6>
                              </div>
                            </div>
                            <div className='col-4 col-lg-4'>
                              <div className='hero-tag'
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                fontSize:11,
                                maxWidth: 81,
                              }}>
                                {item.deal_service} <InfoCircleOutlined />
                              </div>
                            </div>
                          </div>
                          <p>
                              {item.Muliples_of.length > 100 ? item.Muliples_of.substring(0, 100) + '...' : item.Muliples_of}
                          </p>
                            <div className='d-flex raised-txt justify-content-between'>
                            <div>Percent Raised <br/><p className='per'>{parseFloat((((item.total_invested_amount)/(item.deal_fund_requested))*100)+parseInt(item.raiegap)).toFixed(0)} %</p></div>
                                        <div>Closes in <br/><p className='per'>{this.getDifferenceInDays(this.state.todaydate, moment(item.deal_end_date).format('YYYY-MM-DD'))} Days</p></div>
                            </div>
                          <div className='d-flex tags'>
                            {categories.length > 0 && categories.map((cat, index) => {
                              return(
                                <div className='hero-tag' key={index}>{cat}</div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                )
                }
                })}
              </div>
              {/* <a href='/closeddeals' className='view-all-button-link  prime-bg'
              style={{paddingTop: "15px"}}>View All</a> */}

          </div>
          </Spin >
        )}
        <WebFooter />
      </div>
    )
  }
}

export default Deals;
