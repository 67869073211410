
import React, { Component } from 'react';
import { Steps  } from 'antd';
import BasicDetails from './BasicDetails';
import Step2 from './IdeaBusiness';
import Step3 from './IntellectualProperty';
import Step4 from './MobileApp';
import Step5 from './IndustryMarket';
import Step6 from './Competition';
import Step7 from './SWOT';
import Step8 from './CompanyLegalEntity';
import Step9 from './SocialMediaPresence';
import Step10 from './GoToMarket';
import Step11 from './Financials';
import Step12 from './Capital';
import Step13 from './Salaries';
import Step14 from './FundingDetails';
import Step15 from './UseOfFunds';
import Step16 from './Compliances';
import Step17 from './OtherImportantIndicators';
import Step18 from './Refrences';
import Step19 from './Declaration';
import Step20 from './SupportingDocuments';

import Bridge from '../../constants/Bridge';
 
import $ from 'jquery';


const { Step } = Steps;

class FounderNewRegister extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeform:0,
      class0:'',
			class1:'',
			class2:'',
			class3:'',
			class4:'',
			class5:'',
			class6:'',
			class7:'',
			class8:'',
			class9:'',
			class10:'',
			class11:'',
			class12:'',
			class13:'',
			class14:'',
			class15:'',
			class16:'',
			class17:'',
			class18:'',
			class19:'',

      error_status_0:'',
			error_status_1:'',
			error_status_2:'',
			error_status_3:'',
			error_status_4:'',
			error_status_5:'',
			error_status_6:'',
			error_status_7:'',
			error_status_8:'',
			error_status_9:'',
			error_status_10:'',
			error_status_11:'',
			error_status_12:'',
			error_status_13:'',
			error_status_14:'',
			error_status_15:'',
			error_status_16:'',
			error_status_17:'',
			error_status_18:'',
			error_status_19:'',

      validated:false,
      f1_status:'',
      f2_status:'',
      f3_status:'',
      f4_status:'',
      f5_status:'',
      f6_status:'',
      f7_status:'',
      f8_status:'',
      f9_status:'',
      f10_status:'',
      f11_status:'',
      f12_status:'',
      f13_status:'',
      f14_status:'',
      f15_status:'',
      f16_status:'',
      f17_status:'',
      f18_status:'',
      f19_status:'',
      f20_status:'',
      showInstruction:true,
    }
  }

  componentDidMount() {
    if(!localStorage.getItem('founder_id'))  {
      window.location.assign('/founder-login');
    }
    if(localStorage.getItem('founder_id'))  {
      let id = localStorage.getItem('founder_id');
      this.getData(id);
    }
    // localStorage.removeItem('register_id');
  }

  getData = (id) => {
    let params = {
      founder_id:id
    }
    Bridge.founder.getFounderDetails(params).then((result) => {
      if (result.status==1) { 

        /// showing for done
        if(result.data[0].send_me_copy_of_response) {
          // this.setState({class18:' success-tab'});
        }
        if(result.data[0].what_valuation_will_safe &&
          result.data[0].dependence_on_any_specific_founder &&
          result.data[0].regulartory_issues &&
          result.data[0].licences_and_permissions &&
          result.data[0].team_size &&
          result.data[0].is_company_paying_commision_above_5_per &&
          result.data[0].is_company_paying_commision_above_10_per &&
          result.data[0].possible_exit_opportunities &&
          result.data[0].subsidiaries &&
          result.data[0].sister_concerns &&
          result.data[0].related_party_transactions &&
          result.data[0].legal_risk_plan_to_migrate &&
          result.data[0].amy_change_by_founders &&
          result.data[0].demo_video_link &&
          result.data[0].supported_documents &&
          result.data[0].media_coverage &&
          result.data[0].awards_and_recognitions &&
          result.data[0].recognized_as_startup_by_dpiit &&
          result.data[0].any_specific_information_to_share) {
          // this.setState({class16:' success-tab'});
        }
        if(result.data[0].are_you_registered_for_gst &&
          result.data[0].status_of_gst_compliance &&
          result.data[0].date_of_last_audited_balance_sheet &&
          result.data[0].date_of_filling_last_itr &&
          result.data[0].date_of_last_agm &&
          result.data[0].pending_complience_related_to_roc &&
          result.data[0].past_days &&
          result.data[0].list_of_other_situatory) {
          // this.setState({class15:' success-tab'});
        } 
        if(result.data[0].funds_required &&
          result.data[0].expected_runway_with_current_fund_raise &&
          result.data[0].desired_valuation_for_current_fund_raise &&
          result.data[0].logic_for_desired_valuation &&
          result.data[0].are_you_open_to_consider_logical_lower_valuation &&
          result.data[0].capex_immediately &&
          result.data[0].capex_future_plans &&
          result.data[0].use_of_funds_product_development &&
          result.data[0].use_of_funds_marketing &&
          result.data[0].use_of_funds_repayment &&
          result.data[0].use_of_funds_salaries_in_per &&
          result.data[0].use_of_funds_cost_and_commision &&
          result.data[0].use_of_funds_other &&
          result.data[0].are_you_open_to_consider_logical_lower_valuation) {
          // this.setState({class14:' success-tab'});
        }  
        if(result.data[0].have_you_raised_fund_for_startup) {
          // this.setState({class13:' success-tab'});
        } 
        if(result.data[0].founders_current_salery &&
          result.data[0].date_of_last_increase_founders_salary &&
          result.data[0].core_team_current_salary &&
          result.data[0].total_salary_including_core_team_salary) {
          // this.setState({class12:' success-tab'});
        }  
        if(result.data[0].authorized_captial_of_company &&
          result.data[0].paid_up_capital_company &&
          result.data[0].percentage_holding_by_core_team &&
          result.data[0].reserved_for_esop &&
          result.data[0].percentage_holding_of_others &&
          result.data[0].actual_amount_real_salaries_taken &&
          result.data[0].usecure_loans_received_from_founders &&
          result.data[0].usecure_loans_received_from_other &&
          result.data[0].any_other_secured_or_ddebt_from_bank ) {
        // this.setState({class11:' success-tab'});
        }  
        if(result.data[0].name_of_clients &&
          result.data[0].client_retention &&
          result.data[0].revenue_top_5_clients &&
          result.data[0].explaination_economics_of_startup &&
          result.data[0].total_amount_spent_of_product &&
          result.data[0].major_expense_till_date) {
          // this.setState({class10:' success-tab'});
        }  
        if(result.data[0].primary_gtm_strategy &&
          result.data[0].backup_plan_for_strategy &&
          result.data[0].existing_cas &&
          result.data[0].expected_cac_in_future &&
          result.data[0].rational_behinde_any_change_in_cac &&
          result.data[0].ltv_of_customer &&
          result.data[0].rational_behind_ltv_number &&
          result.data[0].ltv_to_cac_ratio ) {
          // this.setState({class9:' success-tab'});
        } 
        if(result.data[0].linkdin || 
          result.data[0].facebook  ||
          result.data[0].instagram ||
          result.data[0].youtube ||
          result.data[0].others
        ) {
          // this.setState({class8:' success-tab'});
        } 
        if(result.data[0].name_of_legality_entity &&
          result.data[0].website &&
          result.data[0].cin_legality_entity &&
          result.data[0].pan_legality_entity &&
          result.data[0].registered_in_country &&
          result.data[0].formality_established_date &&
          result.data[0].activities_start_date_befire_formal &&
          result.data[0].address_registered_office &&
          result.data[0].address_corporate_office &&
          result.data[0].director_1_name &&
          result.data[0].director_1_din &&
          result.data[0].director_2_name &&
          result.data[0].director_2_din &&
          result.data[0].director_3_name &&
          result.data[0].director_3_din &&
          result.data[0].director_4_name &&
          result.data[0].director_4_din ) {
          // this.setState({class7:' success-tab'});
        }
        if(result.data[0].strength_of_your_startup &&
          result.data[0].weakness_of_startup &&
          result.data[0].opportunities_for_startup &&
          result.data[0].threats_for_startup) {
          // this.setState({class6:' success-tab'});
        } 
        if(result.data[0].direct_local_competition &&
          result.data[0].in_direct_local_competition &&
          result.data[0].direct_global_competition &&
          result.data[0].indirect_global_competition &&
          result.data[0].how_different_startup_from_competition &&
          result.data[0].why_difficult_competition &&
          result.data[0].what_are_unfair_disadvantages &&
          result.data[0].most_about_your_competition) {
          // this.setState({class5:' success-tab'});
        } 
        if(result.data[0].relevant_industry &&
          result.data[0].views_on_industry &&
          result.data[0].total_market_size_of_industry &&
          result.data[0].supporting_information_of_narket_size &&
          result.data[0].addressale_market_size &&
          result.data[0].supporting_information_of_demarking_addressable_market) {
          // this.setState({class4:' success-tab'});
        } 
        if(result.data[0].have_any_android_app_startup &&
          result.data[0].have_ios_app) {
          // this.setState({class3:' success-tab'});
        } 
        if(result.data[0].trademark && result.data[0].patents&&
        result.data[0].other_ips&&result.data[0].all_iprs_rwgistered_in_company) {
          // this.setState({class2:' success-tab'});
        }
        if(result.data[0].is_disrupting_existing_market &&
          result.data[0].is_targeting_new_untabed_market &&
          result.data[0].customer_benifit &&
          result.data[0].suplier_benifit &&
          result.data[0].focused_on_product &&
          result.data[0].direct_substitute_available &&
          result.data[0].indirect_substitute_available &&
          result.data[0].risks_perceived &&
          result.data[0].responsibilities_distributted_members &&
          result.data[0].moats &&
          result.data[0].challenges_for_scale_up) {
          // this.setState({class1:' success-tab'});
        }
        if(result.data[0].email && result.data[0].startup_name && result.data[0].primary_contact_person_name
        && result.data[0].primary_contact_person_mobile){
          // this.setState({class0:' success-tab'});
        } 
      } 
      
    });
  }

  activeform = (value) => {
    this.setState({activeform:value});
    $("html, body").animate({
      scrollTop:0
    }, 1000);
  }

  onChange = (value) => {
    this.setState({activeform:value});
  }

  activethistab=(num)=>{
    this.setState({activeform:num});
    $("html, body").animate({scrollTop:0},1000);
    // this.checkforvalidation();
  }

  checkforvalidation=()=>{
    let params = {
      founder_id:localStorage.getItem('founder_id'),
    }
    Bridge.founder.getFounderDetails(params).then((result) => {
      if (result.status==1) { 

        let validate=false;
        console.log('result',result.data);

        if(result.data[0].pitch!='') {
          
          this.setState({class18:' success-tab',error_status_18:'1'});
          validate=true;
        }else{
          this.setState({class18:' error-tab',error_status_18:'0'});
          validate=false;
        }
        
        /// showing for done
        if(result.data[0].pitch &&
          result.data[0].f19_status=='success') {
          this.setState({class18:' success-tab',error_status_18:'1'});
          validate=true;
        }
        else{
          this.setState({class18:' error-tab',error_status_18:'0'});
          validate=false;
        }

        this.setState({class17:' success-tab',error_status_17:'1'});
        validate=true;

        if(
          result.data[0].f17_status=='success' &&
          result.data[0].what_valuation_will_safe &&
          result.data[0].dependence_on_any_specific_founder &&
          result.data[0].regulartory_issues &&
          result.data[0].licences_and_permissions &&
          result.data[0].team_size &&
          result.data[0].is_company_paying_commision_above_5_per &&
          result.data[0].is_company_paying_commision_above_10_per &&
          result.data[0].possible_exit_opportunities &&
          result.data[0].subsidiaries &&
          result.data[0].sister_concerns &&
          result.data[0].related_party_transactions &&
          result.data[0].legal_risk_plan_to_migrate &&
          result.data[0].amy_change_by_founders 
          // result.data[0].demo_video_link &&
          // result.data[0].supported_documents &&
          // result.data[0].media_coverage &&
          // result.data[0].awards_and_recognitions &&
          // result.data[0].recognized_as_startup_by_dpiit &&
          // result.data[0].any_specific_information_to_share
          ) {
          this.setState({class16:' success-tab',error_status_16:'1',});
          validate=true;
        }
         else{
          this.setState({class16:' error-tab',error_status_16:'0',});
          validate=false;
        }

        if(
          result.data[0].f16_status=='success' &&
          result.data[0].are_you_registered_for_gst &&
          result.data[0].status_of_gst_compliance &&
          result.data[0].date_of_last_audited_balance_sheet &&
          result.data[0].date_of_filling_last_itr &&
          result.data[0].date_of_last_agm &&
          result.data[0].pending_complience_related_to_roc &&
          result.data[0].past_days &&
          result.data[0].list_of_other_situatory) {
          this.setState({class15:' success-tab',error_status_15:'1'});
          validate=true;
        }  
        else{
          this.setState({class15:' error-tab',error_status_15:'0'});
          validate=false;
        }

        if(result.data[0].f15_status=='success' &&
        result.data[0].funds_required &&
          result.data[0].expected_runway_with_current_fund_raise &&
          result.data[0].desired_valuation_for_current_fund_raise &&
          result.data[0].logic_for_desired_valuation &&
          result.data[0].are_you_open_to_consider_logical_lower_valuation &&
          result.data[0].capex_immediately &&
          result.data[0].capex_future_plans &&
          result.data[0].use_of_funds_product_development &&
          result.data[0].use_of_funds_marketing &&
          result.data[0].use_of_funds_repayment &&
          result.data[0].use_of_funds_salaries_in_per &&
          result.data[0].use_of_funds_cost_and_commision &&
          result.data[0].use_of_funds_other &&
          result.data[0].are_you_open_to_consider_logical_lower_valuation) {
          this.setState({class14:' success-tab',error_status_14:'1'});
          validate=true;
        }  
        else{
          this.setState({class14:' error-tab',error_status_14:'0'});
          validate=false;
        }
        if(result.data[0].have_you_raised_fund_for_startup &&
          result.data[0].f14_status=='success'
          ) {
          this.setState({class13:' success-tab',error_status_13:'1'});
          validate=true;
        } 
         else{
          this.setState({class13:' error-tab',error_status_13:'0'});
          validate=false;
        }
        if(result.data[0].founders_current_salery &&
          result.data[0].date_of_last_increase_founders_salary &&
          result.data[0].core_team_current_salary &&
          result.data[0].total_salary_including_core_team_salary &&
          result.data[0].f13_status=='success') {
          this.setState({class12:' success-tab',error_status_12:'1'});
          validate=true;
        }  
        else{
          this.setState({class12:' error-tab',error_status_12:'0'});
          validate=false;
        }
        if(result.data[0].authorized_captial_of_company &&
          result.data[0].paid_up_capital_company &&
          result.data[0].percentage_holding_by_founders &&
          result.data[0].percentage_holding_by_core_team &&
          result.data[0].reserved_for_esop &&
          result.data[0].percentage_holding_of_others &&
          result.data[0].actual_amount_real_salaries_taken &&
          result.data[0].usecure_loans_received_from_founders &&
          result.data[0].usecure_loans_received_from_other &&
          result.data[0].any_other_secured_or_ddebt_from_bank &&
          result.data[0].f12_status=='success') {
          this.setState({class11:' success-tab',error_status_11:'1'});
          validate=true;
        }  
        else{
          this.setState({class11:' error-tab',error_status_11:'0'});
          validate=false;
        }
        if(result.data[0].name_of_clients &&
          result.data[0].client_retention &&
          result.data[0].revenue_top_5_clients &&
          result.data[0].explaination_economics_of_startup &&
          result.data[0].total_amount_spent_of_product &&
          result.data[0].total_capex_of_startup &&
          result.data[0].major_expense_till_date && 
          result.data[0].f11_status=='success') {
          this.setState({class10:' success-tab',error_status_10:'1'});
          validate=true;
        }  
        else{
          this.setState({class10:' error-tab',error_status_10:'0'});
          validate=false;
        }
        if(result.data[0].primary_gtm_strategy &&
          result.data[0].backup_plan_for_strategy &&
          result.data[0].existing_cas &&
          result.data[0].expected_cac_in_future &&
          result.data[0].rational_behinde_any_change_in_cac &&
          result.data[0].ltv_of_customer &&
          result.data[0].rational_behind_ltv_number &&
          result.data[0].ltv_to_cac_ratio &&
          result.data[0].f10_status=='success') {
          this.setState({class9:' success-tab',error_status_9:'1'});
          validate=true;
        } 
         else{
          this.setState({class9:' error-tab',error_status_9:'0'});
          validate=false;
        }
        this.setState({class8:' success-tab',error_status_8:'1'});

        if(result.data[0].name_of_legality_entity &&
          // result.data[0].website &&
          result.data[0].cin_legality_entity &&
          result.data[0].pan_legality_entity &&
          result.data[0].registered_in_country &&
          result.data[0].formality_established_date &&
          // result.data[0].activities_start_date_befire_formal &&
          result.data[0].address_registered_office &&
          result.data[0].address_corporate_office &&
          result.data[0].director_1_name &&
          result.data[0].director_1_din && 
          result.data[0].f8_status=='success'
          // result.data[0].director_2_name &&
          // result.data[0].director_2_din &&
          // result.data[0].director_3_name &&
          // result.data[0].director_3_din &&
          // result.data[0].director_4_name &&
          // result.data[0].director_4_din
           ) {
          this.setState({class7:' success-tab',error_status_7:'1'});
          validate=true;
        }
         else{
          this.setState({class7:' error-tab',error_status_7:'0'});
          validate=false;
        }
        if(
          result.data[0].f7_status=='success' &&
          result.data[0].strength_of_your_startup &&
          result.data[0].weakness_of_startup &&
          result.data[0].opportunities_for_startup &&
          result.data[0].threats_for_startup) {
          this.setState({class6:' success-tab',error_status_6:'1'});
          validate=true;
        } 
         else{
          this.setState({class6:' error-tab',error_status_6:'0'});
          validate=false;
        }
        
        if(
          result.data[0].f6_status=='success' &&
          result.data[0].direct_local_competition &&
          result.data[0].in_direct_local_competition &&
          result.data[0].direct_global_competition &&
          result.data[0].indirect_global_competition &&
          result.data[0].how_different_startup_from_competition &&
          result.data[0].why_difficult_competition &&
          result.data[0].what_are_unfair_disadvantages &&
          result.data[0].most_about_your_competition) {
          this.setState({class5:' success-tab',error_status_5:'1'});
          validate=true;
        } 
         else{
          this.setState({class5:' error-tab',error_status_5:'0'});
          validate=false;
        }
        
        if(
          result.data[0].f5_status=='success' &&
          result.data[0].relevant_industry &&
          result.data[0].views_on_industry &&
          result.data[0].total_market_size_of_industry &&
          result.data[0].supporting_information_of_narket_size &&
          result.data[0].addressale_market_size &&
          result.data[0].supporting_information_of_demarking_addressable_market) {
          this.setState({class4:' success-tab',error_status_4:'1'});
          validate=true;
        } 
         else{
          this.setState({class4:' error-tab',error_status_4:'0'});
          validate=false;
        }
        if(result.data[0].have_any_android_app_startup &&
          result.data[0].f4_status=='success' &&
          result.data[0].have_ios_app) {
          this.setState({class3:' success-tab',error_status_3:'1'});
          validate=true;
        } 
         else{
          this.setState({class3:' error-tab',error_status_3:'0'});
          validate=false;
        }
        if(
          result.data[0].f3_status=='success' &&
          result.data[0].trademark &&
          result.data[0].patents &&
          result.data[0].other_ips &&
          result.data[0].all_iprs_rwgistered_in_company) {
          this.setState({class2:' success-tab',error_status_2:'1'});
          validate=true;
        }
        else{
          this.setState({class2:' error-tab',error_status_2:'0'});
          validate=false;
        }

        if(
          result.data[0].f2_status=='success' &&
          result.data[0].is_disrupting_existing_market &&
          result.data[0].is_targeting_new_untabed_market &&
          result.data[0].customer_benifit &&
          result.data[0].suplier_benifit &&
          result.data[0].focused_on_product &&
          result.data[0].direct_substitute_available &&
          result.data[0].indirect_substitute_available &&
          result.data[0].risks_perceived &&
          result.data[0].responsibilities_distributted_members &&
          result.data[0].moats &&
          result.data[0].challenges_for_scale_up) {
          this.setState({class1:' success-tab',error_status_1:'1'});
          validate=true;
        }  
        else{
          this.setState({class1:' error-tab',error_status_1:'0'});
          validate=false;
        }
        // basic details
        if(result.data[0].email && result.data[0].startup_name && result.data[0].primary_contact_person_name
        && result.data[0].primary_contact_person_mobile &&
        result.data[0].f1_status=='success'

        ){
          this.setState({class0:' success-tab',error_status_0:'1'});
          validate=true;
        } 
        else{
          this.setState({class0:' error-tab',error_status_0:'0'});
          validate=false;
        }

        this.setState({validated:validate});
      } 
    });
  }

  render() {
    return (
      <div>
        {this.state.showInstruction?
        <div className="m-5 ppnt">
              <div
                className="m-3 py-3"
                style={{ paddingTop: "63px !important" }}
              >
                <h1 className='mt-5'>Instruction for startup form</h1>
                  <br/>
                  <p>
                    Tell us a little about your company. This will help us understand your business better.<br/>
                    <span style={{color:'red'}}>( Instruction: Startup form and Assessment Forms are best viewed on PC/Laptop. )</span>
                  </p>
               
              </div>

              <hr
                style={{
                  border: "1px solid rgb(170 167 167)",
                  background: "#ddd",
                  margin: "33px 0",
                }}
              /> 
              
              <center >
              
                <button
                  onClick={() => {this.setState({showInstruction:false})}}
                  className="small-button-dark prime-bg  "
                  style={{
                    
                    fontsize: 18,
                    padding: "0 0",
                    marginBottom: 20,
                  }}
                >
                  Lets start
                </button>
              </center>
              
              <img
                src="./assets/images/founders/startup-instruction.png"
                alt='startup-instruction'
                style={{
                  maxWidth: "60%",
                  margin: "0 auto",
                  display: "flex",
                }}
              />
                
              <center>
              <br/>
                <button
                  onClick={() => {this.setState({showInstruction:false})}}
                  className="small-button-dark prime-bg"
                  style={{
                    fontsize: 18,
                    padding: "0 0",
                  }}
                >
                  Lets start
                </button>
              </center>
            </div>
        :<section className="rfoundation-section" 
          style={{marginTop:100}}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1>Information about Startup</h1>
                  <br/>
                  <p>
                    Tell us a little about your company. This will help us understand your business better.<br/>
                    <span style={{color:'red'}}>( Instruction: Startup form and Assessment Forms are best viewed on PC/Laptop. )</span>
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className="col-lg-4">
                  <div className='multistep-form-icons'>
                    <ul>
                      <li onClick={()=>this.activethistab(0)}>
                        <div>
                          <div 
                          className={this.state.activeform==0?'circle active-tab':'circle '+this.state.class0}
                          >
                            {(this.state.activeform==0 || this.state.class0=='') && '1'}
                            {this.state.activeform!=0 && this.state.class0==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=0 && this.state.class0==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Basic Details</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(1)}>
                        <div>
                          <div 
                          className={this.state.activeform==1?'circle active-tab':'circle '+this.state.class1}
                          >
                            {(this.state.activeform==1 || this.state.class1=='') && '2'}
                            {this.state.activeform!=1 && this.state.class1==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=1 && this.state.class1==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Idea/Business</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(2)}>
                        <div>
                          <div 
                          className={this.state.activeform==2?'circle active-tab':'circle '+this.state.class2}
                          >
                            {(this.state.activeform==2 || this.state.class2=='') && '3'}
                            {this.state.activeform!=2 && this.state.class2==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=2 && this.state.class2==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Intellectual Property</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(3)}>
                        <div>
                          <div 
                          className={this.state.activeform==3?'circle active-tab':'circle'+this.state.class3}
                          >
                            {(this.state.activeform==3 || this.state.class3=='') && '4'}
                            {this.state.activeform!=3 && this.state.class3==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=3 && this.state.class3==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Mobile App</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(4)}>
                        <div>
                        <div 
                          className={this.state.activeform==4?'circle active-tab':'circle'+this.state.class4}
                          >
                            {(this.state.activeform==4 || this.state.class4=='') && '5'}
                            {this.state.activeform!=4 && this.state.class4==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=4 && this.state.class4==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Industry Market</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(5)}>
                        <div>
                          <div 
                          className={this.state.activeform==5?'circle active-tab':'circle'+this.state.class5}
                          >
                            {(this.state.activeform==5 || this.state.class5=='') && '6'}
                            {this.state.activeform!=5 && this.state.class5==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=5 && this.state.class5==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Competition</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(6)}>
                        <div>
                          <div 
                          className={this.state.activeform==6?'circle active-tab':'circle'+this.state.class6}
                          >
                            {(this.state.activeform==6 || this.state.class6=='') && '7'}
                            {this.state.activeform!=6 && this.state.class6==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=6 && this.state.class6==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>SWOT</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(7)}>
                        <div>
                          <div 
                          className={this.state.activeform==7?'circle active-tab':'circle'+this.state.class7}
                          >
                            {(this.state.activeform==7 || this.state.class7=='') && '8'}
                            {this.state.activeform!=7 && this.state.class7==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=7 && this.state.class7==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Company Legal Entity</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(8)}>
                        <div>
                          <div 
                          className={this.state.activeform==8?'circle active-tab':'circle'+this.state.class8}
                          >
                             {(this.state.activeform==8 || this.state.class8=='') && '9'}
                            {this.state.activeform!=8 && this.state.class8==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=8 && this.state.class8==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Social Media Presence</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(9)}>
                        <div>
                          <div 
                          className={this.state.activeform==9?'circle active-tab':'circle'+this.state.class9}
                          >
                            {(this.state.activeform==9 || this.state.class9=='') && '10'}
                            {this.state.activeform!=9 && this.state.class9==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=9 && this.state.class9==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Go To Market</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(10)}>
                        <div>
                          <div 
                          className={this.state.activeform==10?'circle active-tab':'circle'+this.state.class10}
                          >
                            {(this.state.activeform==10 || this.state.class10=='') && '11'}
                            {this.state.activeform!=10 && this.state.class10==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=10 && this.state.class10==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Financials</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(11)}>
                        <div>
                          <div 
                          className={this.state.activeform==11?'circle active-tab':'circle'+this.state.class11}
                          >
                            {(this.state.activeform==11 || this.state.class11=='') && '12'}
                            {this.state.activeform!=11 && this.state.class11==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=11 && this.state.class11==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Capital</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(12)}>
                        <div>
                          <div 
                          className={this.state.activeform==12?'circle active-tab':'circle'+this.state.class12}
                          >
                            {(this.state.activeform==12 || this.state.class12=='') && '13'}
                            {this.state.activeform!=12 && this.state.class12==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=12 && this.state.class12==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Salaries</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(13)}>
                        <div>
                          <div 
                          className={this.state.activeform==13?'circle active-tab':'circle'+this.state.class13}
                          >
                            {(this.state.activeform==13 || this.state.class13=='') && '14'}
                            {this.state.activeform!=13 && this.state.class13==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=13 && this.state.class13==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Funding Details</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(14)}>
                        <div>
                          <div 
                          className={this.state.activeform==14?'circle active-tab':'circle'+this.state.class14}
                          >
                            {(this.state.activeform==14 || this.state.class14=='') && '15'}
                            {this.state.activeform!=14 && this.state.class14==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=14 && this.state.class14==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Use Of Funds</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(15)}>
                        <div>
                          <div 
                          className={this.state.activeform==15?'circle active-tab':'circle'+this.state.class15}
                          >
                            {(this.state.activeform==15 || this.state.class15=='') && '16'}
                            {this.state.activeform!=15 && this.state.class15==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=15 && this.state.class15==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Compliances</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(16)}>
                        <div>
                          <div 
                          className={this.state.activeform==16?'circle active-tab':'circle'+this.state.class16}
                          >
                            {(this.state.activeform==16 || this.state.class16=='') && '17'}
                            {this.state.activeform!=16 && this.state.class16==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=16 && this.state.class16==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Other Important Indicators</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(17)}>
                        <div>
                          <div 
                          className={this.state.activeform==17?'circle active-tab':'circle'+this.state.class17}
                          >
                             {(this.state.activeform==17 || this.state.class17=='') && '18'}
                            {this.state.activeform!=17 && this.state.class17==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=17 && this.state.class17==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>References</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(18)}>
                        <div>
                          <div 
                            className={this.state.activeform==18?'circle active-tab':'circle'+this.state.class18}
                          >
                            {(this.state.activeform==18 || this.state.class18=='') && '19'}
                            {this.state.activeform!=18 && this.state.class18==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=18 && this.state.class18==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Supporting Documents</span>
                          <div className='line'></div>
                        </div>
                      </li>
                      <li onClick={()=>this.activethistab(19)}>
                        <div>
                          <div 
                            className={this.state.activeform==19?'circle active-tab':'circle'+this.state.class19}
                          >
                            {(this.state.activeform==19 || this.state.class19=='') && '20'}
                            {this.state.activeform!=19 && this.state.class19==' success-tab' && <i style={{fontSize:28}} className='bx bx-check'></i>}
                            {this.state.activeform!=19 && this.state.class19==' error-tab' && <i style={{fontSize:28}} className='bx bx-x'></i>}
                          </div>
                          <span>Declaration</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8">
                  {this.state.activeform=='0' && (
                    <BasicDetails 
                      activate={() => this.activeform(1)} 
                      next={() => this.activeform(1)} 
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_0}
                      check={()=>this.checkforvalidation()}
                    />
                  )}
                  {this.state.activeform=='1' && (
                    <Step2 
                      activate={() => this.activeform(2)} 
                      prev={() => this.activeform(0)}  
                      next={() => this.activeform(2)}  
                      onClick={() => this.activatethisform(1)}
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_1}
                      check={()=>this.checkforvalidation()}
                    />
                  )}
                  {this.state.activeform=='2' && (
                    <Step3 
                      activate={() => this.activeform(3)} 
                      prev={() => this.activeform(1)}  
                      next={() => this.activeform(3)} 
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_2}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='3' && (
                    <Step4 
                      activate={() => this.activeform(4)}
                      prev={() => this.activeform(2)}  
                      next={() => this.activeform(4)} 
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_3}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='4' && (
                    <Step5 
                      activate={() => this.activeform(5)}
                      prev={() => this.activeform(3)}  
                      next={() => this.activeform(5)}  
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_4}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='5' && (
                    <Step6 
                      activate={() => this.activeform(6)}
                      prev={() => this.activeform(4)}  
                      next={() => this.activeform(6)}  
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_5}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='6' && (
                    <Step7 
                      activate={() => this.activeform(7)} 
                      prev={() => this.activeform(5)}  
                      next={() => this.activeform(7)}  
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_6}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='7' && (
                    <Step8 
                      activate={() => this.activeform(8)} 
                      prev={() => this.activeform(6)}  
                      next={() => this.activeform(8)}  
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_7}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='8' && (
                    <Step9 
                      activate={() => this.activeform(9)} 
                      prev={() => this.activeform(7)}  
                      next={() => this.activeform(9)}  
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_8}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='9' && (
                    <Step10 
                      activate={() => this.activeform(10)} 
                      prev={() => this.activeform(8)}  
                      next={() => this.activeform(10)} 
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_9}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='10' && (
                    <Step11 
                      activate={() => this.activeform(11)} 
                      prev={() => this.activeform(9)}  
                      next={() => this.activeform(11)}
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_10}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='11' && (
                    <Step12 
                      activate={() => this.activeform(12)} 
                      prev={() => this.activeform(10)}  
                      next={() => this.activeform(12)}
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_11}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='12' && (
                    <Step13 
                      activate={() => this.activeform(13)} 
                      prev={() => this.activeform(11)}  
                      next={() => this.activeform(13)}
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_12}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='13' && (
                    <Step14 
                      activate={() => this.activeform(14)} 
                      prev={() => this.activeform(12)}  
                      next={() => this.activeform(14)}
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_13}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='14' && (
                    <Step15 
                      activate={() => this.activeform(15)} 
                      prev={() => this.activeform(13)}  
                      next={() => this.activeform(15)}
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_14}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='15' && (
                    <Step16 
                      activate={() => this.activeform(16)}
                      prev={() => this.activeform(14)}  
                      next={() => this.activeform(16)} 
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_15}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='16' && (
                    <Step17 
                      activate={() => this.activeform(17)} 
                      prev={() => this.activeform(16)}  
                      next={() => this.activeform(17)} 
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_16}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='17' && (
                    <Step18 
                      activate={() => this.activeform(18)} 
                      prev={() => this.activeform(17)}  
                      next={() => this.activeform(18)} 
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_17}
                      check={()=>this.checkforvalidation()}
                    />
                  )}                    
                  {this.state.activeform=='18' && (
                    <Step20 
                      activate={() => this.activeform(19)} 
                      prev={() => this.activeform(17)}  
                      next={() => this.activeform(19)} 
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_18}
                      check={()=>this.checkforvalidation()}
                    />
                  )}
                  {this.state.activeform=='19' && (
                    <Step19
                      activate={() => this.activeform(19)} 
                      prev={() => this.activeform(18)}  
                      next={() => this.activeform(19)} 
                      fatchdata={()=>this.getData(localStorage.getItem('founder_id'))}
                      error={this.state.error_status_19}
                      validated={this.state.validated}
                      check={()=>this.checkforvalidation()}
                    />
                  )}    
                </div>
              </div>
            </div>
          </section>}
      </div>
    )
  }
}
 

export default FounderNewRegister;



 
 

 