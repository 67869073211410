
import React, { Component } from 'react';
import WebHeader from '../../common/WebHeader';
import WebFooter from '../../common/WebFooter';
import { message, Spin, Select, Radio, Input } from 'antd';
import Bridge from '../../constants/Bridge';
import moment from 'moment';
import $ from 'jquery';
import Fetchurldata from './Fetchurldata';
import CountdownTimer from 'timer-countdown';

const { Option } = Select;
class Step1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      first_name:'',
      middle_name:'',
      last_name:'',
      email:'',
      loading:false,
      screen1:true,
      screen2:false,
      screen3:false,
      nationality:'',
      otpscreen:false,
      num1:"",
      num2:"",
      num3:"",
      num4:"",
      num5:"",
      num6:"",
      otp:"",
      otpoutput:"",
      timer:'00:00',
      istimerstarted:false,
      minutes:2,
      seconds:0,
      otp_restriction_date:'',
      minutes2:10,
      seconds2:0,
      refferal_code:'',
      is_refferal_code_matched:'',
      show_error:false,
      show_data:'none',
      phone:'',
      phone1:'',
      mobileotp:'122411',
      emailotp:'111232',
      otpsent:false,
      email_otpsent: false,
      mobile_no_otp:'',
      email_otp:'',
      verfied:false,
      email_verified: false,
      mobileminuts:2,
      mobileseconds:0,
      istimerstarted2:false,
      typeofmember:'Indian Resident',
      country_code:'',
      countemailseconds:119000,
    }
  }
  componentDidMount(){
    let otp=this.generateOTP();
    this.setState({
      otp:otp.length==6 ? Number(otp).toFixed() : '144255',
      otp_restriction_date: moment(),
    });
    if(localStorage.getItem('investor_id') || localStorage.getItem('founder_id')){
      if(localStorage.getItem('investor_id')){
        window.location.assign('/deals');
        this.setState({show_data:'none'});
        return;
      }
      if(localStorage.getItem('founder_id')){
        window.location.assign('/founder-dashboard');
        this.setState({show_data:'none'});
        return;
      }
    }else{
      this.setState({show_data:'block'});
    }
  }

  generateOTP = () => {
    var digits="0123456789";
    let OTP="";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  };
  // register
  register = () => { 
    if(!this.state.first_name) {
      message.error('Please enter your first name');
      return;
    }else if(!this.state.last_name) {
      message.error('Please enter your last name.');
      return;
    } else if(!this.state.email) {
      message.error('Please enter your email.');
      return;
    }  
    // else if(this.state.phone.length!='10') {
    //   message.error('Invalid mobile.');
    //   return;
    // }
    if(this.state.typeofmember=='Indian Resident'){
      if(this.state.verfied==false) {
        message.error('Invalid mobile no.');
        return;
      }

      else if(this.state.phone.length!='10') {
        message.error('Invalid mobile.');
        return;
      }
    }  
    
    if(this.state.typeofmember=='Non Resident'){
      if(this.state.phone.length <= '7' || this.state.phone.length >= '15') {
        message.error('Invalid mobile no.');
        console.log(this.state.phone);
        return;
      }
    } 
   
    let string = this.state.first_name+' '+this.state.last_name;
    let newArray = string.split(' ');
    let name='';
    if(newArray.length=='3'){
      let firstChar = newArray[0].charAt(0).toUpperCase(); 
      let firs2tChar = newArray[2].charAt(0).toUpperCase(); 
      name=firstChar+''+firs2tChar;
    }else if(newArray.length=='2'){
      let firstChar = newArray[0].charAt(0).toUpperCase(); 
      let firs2tChar = newArray[1].charAt(0).toUpperCase(); 
      name=firstChar+''+firs2tChar;
    }
    let params={
      first_name: this.state.first_name,
      middle_name: this.state.middle_name,
      last_name: this.state.last_name,
      email: this.state.email,
      nationality: this.state.typeofmember,
      refferal_code:this.state.refferal_code,
      reffered_code2:name+'RR',
      is_refferal_code_matched: this.state.is_refferal_code_matched,
      phone:this.state.phone,
      phone1:this.state.phone1,
      country_code:this.state.country_code,
    }
   
    // this.setState({loading:true});
    Bridge.investor.register(params).then((result) => {
      if (result.status == 1) {
        message.success(result.message);
        this.setState({loading:false});
        if(result.data) {
          console.log('result',result);
          // return
          // localStorage.removeItem('reg_id');
          localStorage.setItem('reg_id', result.data);
          window.location.assign('/investor-registration');
        }
      } else {
        message.error(result.message);
        this.setState({loading: false});
      }
    });
  }
  handleChange  = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  //google login function
  // signInWithGoogle=()=>{
  //   const provider=new GoogleAuthProvider();
  //   signInWithPopup(authentication,provider)
  //   .then((re)=>{console.log(re)})
  //   .catch((err)=>{console.log(err)});
  // }
   //end function
  handleChangeSelect = (e) => {
    this.setState({
      nationality:e.target.value,
    });
  }

  sendotptomail = () => {                 
    if(!this.state.email) {
      message.warning('Please enter your email.');
      return;
    }
    let params={
      email: this.state.email
    }
    Bridge.sendregisterotp(params).then((result) => {
      if(result.status==1){
        message.success('OTP sent successfully.');
        this.setState({
          email_otpsent:true,
          istimerstarted:true,
          loading: false,
        },()=>this.startcountdown());
        this.start10mincountdown();
      } else {
        message.warning(result.message);
        this.setState({loading:false});
      }
    });
  }
  // verify email otp
  verify_mail_otp=()=>{
    if(!this.state.otp){
      message.warning('Please enter your otp');
      return;
    }
    if(!this.state.email){
      message.warning('Please enter your email.');
      return;
    }
    let params ={
      otp: this.state.num1,
      email: this.state.email
    }
    console.log('params',params);
    this.setState({loading: true});
    Bridge.investor.verify_mail_otp(params).then((result) => {
      if(result.status==1){
        message.success('Email is verified successfully.');
        this.setState({email_verified:true,loading:false});
      } else {
        message.warning('Something is went wrong. Please try again.');
        this.setState({loading:false});
      }
    });
    
  }
  showsecondscreen = () => {
    if(!this.state.first_name) {
      message.warning('Please enter your first name');
      return;
    }else if(!this.state.last_name) {
      message.warning('Please enter your last name.');
      return;
    } else if(!this.state.email) {
      message.warning('Please enter your email.');
      return;
    } else if(this.state.phone.length!='10') {
      message.warning('Please enter your phone.');
      return;
    } 
    if(this.state.typeofmember=='Indian Resident'){
      if(this.state.verfied==false) {
        message.warning('Please verify your mobile no');
        return;
      } 
    }
    let params={
      email: this.state.email
    };
    this.setState({loading: true});
    Bridge.sendregisterotp(params).then((result) => {
      if(result.status==1){
        message.success('OTP sent successfully.');
        this.setState({
          screen2:false,
          screen1:false,
          otpscreen:true,
          otpoutput:result.data,
          istimerstarted:true,
          loading: false,
        },()=>this.startcountdown());
        this.start10mincountdown();
      } else {
        message.warning(result.message);
        this.setState({loading:false});
      }
    });
  }
  resendotp=()=>{
    if(!this.state.email){
      message.warning("Invalid email");
      return;
    }
    if(this.state.otp.length!=6){
      this.setState({otp:'123222'});
    }
    let params={
      email: this.state.email,
      mobile:this.state.phone,
      otp: this.state.otp.length!=6 ? '123222' : this.state.otp,
    };
    Bridge.sendregisterotp(params).then((result) => {
      if(result.status==1){
        message.success('OTP sent successfully.');
        this.setState({
          istimerstarted:true,
          minutes:2,
          seconds:0,
        },()=>this.startcountdown());
      } else {
        message.warning(result.message);
      }
    });
  }
  startcountdown=()=>{
    this.setState({countemailseconds:119000,email_verified:false});
  }
  start10mincountdown=()=>{
    this.myInterval = setInterval(() => {
      const { seconds2, minutes2 } = this.state
      if (seconds2 > 0) {
        this.setState(({ seconds2 }) => ({
          seconds2: seconds2 - 1
        }));
      }
      if(seconds2===0){
        if(minutes2===0){
          let otp=this.generateOTP();
          this.setState({
            otp:otp.length!=6 ? '144255' : Number(otp).toFixed(),
            minutes2:10,
            seconds2:0,
          });
        } else {
          this.setState(({ minutes2 }) => ({
            minutes2:minutes2 - 1,
            seconds2:59,
          }));
        }
      } 
    }, 1200);
  }
  showthirdcreen = () => {
    if(!this.state.nationality) {
      message.warning('Invalid nationality.');
      return;
    }
    this.setState({
      screen1:false,
      screen2:false,
      screen3:true,
    });
  }
  onChangeNum1 = (e) => {
    this.setState({
      num1: e.target.value,
    });
    $("#num2").focus();
  };

  onChangeNum2 = (e) => {
    this.setState({
      num2: e.target.value,
    });
    $("#num3").focus();
  };

  onChangeNum3 = (e) => {
    this.setState({
      num3: e.target.value,
    });
    $("#num4").focus();
  };

  onChangeNum4 = (e) => {
    this.setState({
      num4: e.target.value,
    });
    $("#num5").focus();
  };

  onChangeNum5 = (e) => {
    this.setState({
      num5: e.target.value,
    });
    $("#num6").focus();
  };

  check_refferal_code=()=>{
    let params={
      refferal_code:this.state.refferal_code,
    }
    Bridge.investor.check_referral_code_ins(params).then((result) => {
      if(result.status=='1'){
        this.setState({
          is_refferal_code_matched:true,
          show_error:true,
        });
      } else {
        this.setState({
          is_refferal_code_matched:false,
          show_error:true,
        });
      }

    });
  }
   setCode=(value)=>{
    this.setState({refferal_code:value},()=>this.check_refferal_code());
  }
  handleChangePhoneNo=(e)=>{
    this.setState({phone:e.target.value});
  }
  sendotptomobile=()=>{
    if(this.state.phone.length!=10){
      message.warning('Mobile no is required.');
      return;
    }
    let params=`?mobile=${this.state.phone}`;
  
    Bridge.investor.sendotptomobile(params).
    then((result)=>{
      if(result.status=='1'){
        this.setState({otpsent:true},()=>this.startcountdown2());
        message.success('OTP sent to your mobile successfully. please verify');
      }else{
        message.warning(result.message);
      }
    }).catch((err)=>{})
  }
  // verfied 
  verify_mobile_otp=()=>{
    if(this.state.phone.length!=10){
      message.warning('Mobile no is required.');
      return;
    }
    let params={
      mobile:this.state.phone,
      otp:this.state.mobile_no_otp
    };
    Bridge.investor.verify_mobile_otp(params).
    then((result)=>{
      if(result.status=='1'){
        message.success('Mobile Number is verified successfully.'); 
        this.setState({verfied:true});
      }else{
        message.warning('OTP is not correct/matching.');
        return;
      }
    }).catch((err)=>{})
  }
  resent_mobile_otp=()=>{
    // let otp=this.generateOTP();
    let otp;
    if(this.state.mobileotp.length==6 ){Number(this.state.mobileotp).toFixed()}
    else{ this.setState({mobileotp:'123222'})};
    // this.setState({mobileotp:otp});
    if(this.state.phone.length!=10){
      message.warning('Mobile number is required.');
      return;
    }
    let params=`?mobile=${this.state.phone}&otp=${this.state.mobileotp}`;
    Bridge.investor.sendotptomobile(params).
    then((result)=>{
      if(result.status=='1'){
        this.setState({otpsent:true,mobileseconds:0,mobileminuts:2},()=>this.startcountdown2());
        message.success('OTP resent to your mobile successfully. please verify');
      }else{
        message.warning(result.message);
      }
    }).catch((err)=>{})
  }
  
  startcountdown2=()=>{
    this.myInterval=setInterval(()=>{
      const { mobileseconds, mobileminuts } = this.state
      if (mobileseconds > 0) {
        this.setState(({ mobileseconds }) => ({
          mobileseconds: mobileseconds - 1
        }));
      }
      if (mobileseconds === 0) {
        if (mobileminuts === 0) {
          this.setState({istimerstarted2:false});
        } else {
          
          this.setState(({ mobileminuts }) => ({
            mobileminuts:mobileminuts - 1,
            mobileseconds:59,
            istimerstarted2:true,
           

          }));
          // console.log("called",this.state.mobileseconds);
        }
      } 
    },1200);
  }
  onChangeMembertype = (e) => {
    console.log('memberstype',e.target.value);
    this.setState({typeofmember:e.target.value});
  }
  onSearch = (value) => {
    console.log('search:', value);
  }
  emailcompleted = () => {
    // console.log('Timer has completed');
    this.setState({istimerstarted:false})
  }   
  emailtick = (milliseconds) => {
    // console.log('millsieconds',milliseconds);
  }
  render() {
    const selectBefore = (
      <Select 
        value={this.state.country_code} 
        onChange={(value)=>this.setState({country_code:value})} 
        className="select-before"
        style={{width:148,height:45.38}}
      >
        <Option value="">Country Code</Option>
        <Option data-countryCode="DZ" value="213">Algeria (+213)</Option>
        <Option data-countryCode="AD" value="376">Andorra (+376)</Option>
        <Option data-countryCode="AO" value="244">Angola (+244)</Option>
        <Option data-countryCode="AI" value="1264">Anguilla (+1264)</Option>
        <Option data-countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</Option>
        <Option data-countryCode="AR" value="54">Argentina (+54)</Option>
        <Option data-countryCode="AM" value="374">Armenia (+374)</Option>
        <Option data-countryCode="AW" value="297">Aruba (+297)</Option>
        <Option data-countryCode="AU" value="61">Australia (+61)</Option>
        <Option data-countryCode="AT" value="43">Austria (+43)</Option>
        <Option data-countryCode="AZ" value="994">Azerbaijan (+994)</Option>
        <Option data-countryCode="BS" value="1242">Bahamas (+1242)</Option>
        <Option data-countryCode="BH" value="973">Bahrain (+973)</Option>
        <Option data-countryCode="BD" value="880">Bangladesh (+880)</Option>
        <Option data-countryCode="BB" value="1246">Barbados (+1246)</Option>
        <Option data-countryCode="BY" value="375">Belarus (+375)</Option>
        <Option data-countryCode="BE" value="32">Belgium (+32)</Option>
        <Option data-countryCode="BZ" value="501">Belize (+501)</Option>
        <Option data-countryCode="BJ" value="229">Benin (+229)</Option>
        <Option data-countryCode="BM" value="1441">Bermuda (+1441)</Option>
        <Option data-countryCode="BT" value="975">Bhutan (+975)</Option>
        <Option data-countryCode="BO" value="591">Bolivia (+591)</Option>
        <Option data-countryCode="BA" value="387">Bosnia Herzegovina (+387)</Option>
        <Option data-countryCode="BW" value="267">Botswana (+267)</Option>
        <Option data-countryCode="BR" value="55">Brazil (+55)</Option>
        <Option data-countryCode="BN" value="673">Brunei (+673)</Option>
        <Option data-countryCode="BG" value="359">Bulgaria (+359)</Option>
        <Option data-countryCode="BF" value="226">Burkina Faso (+226)</Option>
        <Option data-countryCode="BI" value="257">Burundi (+257)</Option>
        <Option data-countryCode="KH" value="855">Cambodia (+855)</Option>
        <Option data-countryCode="CM" value="237">Cameroon (+237)</Option>
        <Option data-countryCode="CA" value="1">Canada (+1)</Option>
        <Option data-countryCode="CV" value="238">Cape Verde Islands (+238)</Option>
        <Option data-countryCode="KY" value="1345">Cayman Islands (+1345)</Option>
        <Option data-countryCode="CF" value="236">Central African Republic (+236)</Option>
        <Option data-countryCode="CL" value="56">Chile (+56)</Option>
        <Option data-countryCode="CN" value="86">China (+86)</Option>
        <Option data-countryCode="CO" value="57">Colombia (+57)</Option>
        <Option data-countryCode="KM" value="269">Comoros (+269)</Option>
        <Option data-countryCode="CG" value="242">Congo (+242)</Option>
        <Option data-countryCode="CK" value="682">Cook Islands (+682)</Option>
        <Option data-countryCode="CR" value="506">Costa Rica (+506)</Option>
        <Option data-countryCode="HR" value="385">Croatia (+385)</Option>
        <Option data-countryCode="CU" value="53">Cuba (+53)</Option>
        <Option data-countryCode="CY" value="90392">Cyprus North (+90392)</Option>
        <Option data-countryCode="CY" value="357">Cyprus South (+357)</Option>
        <Option data-countryCode="CZ" value="42">Czech Republic (+42)</Option>
        <Option data-countryCode="DK" value="45">Denmark (+45)</Option>
        <Option data-countryCode="DJ" value="253">Djibouti (+253)</Option>
        <Option data-countryCode="DM" value="1809">Dominica (+1809)</Option>
        <Option data-countryCode="DO" value="1809">Dominican Republic (+1809)</Option>
        <Option data-countryCode="EC" value="593">Ecuador (+593)</Option>
        <Option data-countryCode="EG" value="20">Egypt (+20)</Option>
        <Option data-countryCode="SV" value="503">El Salvador (+503)</Option>
        <Option data-countryCode="GQ" value="240">Equatorial Guinea (+240)</Option>
        <Option data-countryCode="ER" value="291">Eritrea (+291)</Option>
        <Option data-countryCode="EE" value="372">Estonia (+372)</Option>
        <Option data-countryCode="ET" value="251">Ethiopia (+251)</Option>
        <Option data-countryCode="FK" value="500">Falkland Islands (+500)</Option>
        <Option data-countryCode="FO" value="298">Faroe Islands (+298)</Option>
        <Option data-countryCode="FJ" value="679">Fiji (+679)</Option>
        <Option data-countryCode="FI" value="358">Finland (+358)</Option>
        <Option data-countryCode="FR" value="33">France (+33)</Option>
        <Option data-countryCode="GF" value="594">French Guiana (+594)</Option>
        <Option data-countryCode="PF" value="689">French Polynesia (+689)</Option>
        <Option data-countryCode="GA" value="241">Gabon (+241)</Option>
        <Option data-countryCode="GM" value="220">Gambia (+220)</Option>
        <Option data-countryCode="GE" value="7880">Georgia (+7880)</Option>
        <Option data-countryCode="DE" value="49">Germany (+49)</Option>
        <Option data-countryCode="GH" value="233">Ghana (+233)</Option>
        <Option data-countryCode="GI" value="350">Gibraltar (+350)</Option>
        <Option data-countryCode="GR" value="30">Greece (+30)</Option>
        <Option data-countryCode="GL" value="299">Greenland (+299)</Option>
        <Option data-countryCode="GD" value="1473">Grenada (+1473)</Option>
        <Option data-countryCode="GP" value="590">Guadeloupe (+590)</Option>
        <Option data-countryCode="GU" value="671">Guam (+671)</Option>
        <Option data-countryCode="GT" value="502">Guatemala (+502)</Option>
        <Option data-countryCode="GN" value="224">Guinea (+224)</Option>
        <Option data-countryCode="GW" value="245">Guinea - Bissau (+245)</Option>
        <Option data-countryCode="GY" value="592">Guyana (+592)</Option>
        <Option data-countryCode="HT" value="509">Haiti (+509)</Option>
        <Option data-countryCode="HN" value="504">Honduras (+504)</Option>
        <Option data-countryCode="HK" value="852">Hong Kong (+852)</Option>
        <Option data-countryCode="HU" value="36">Hungary (+36)</Option>
        <Option data-countryCode="IS" value="354">Iceland (+354)</Option>
        <Option data-countryCode="IN" value="91">India (+91)</Option>
        <Option data-countryCode="ID" value="62">Indonesia (+62)</Option>
        <Option data-countryCode="IR" value="98">Iran (+98)</Option>
        <Option data-countryCode="IQ" value="964">Iraq (+964)</Option>
        <Option data-countryCode="IE" value="353">Ireland (+353)</Option>
        <Option data-countryCode="IL" value="972">Israel (+972)</Option>
        <Option data-countryCode="IT" value="39">Italy (+39)</Option>
        <Option data-countryCode="JM" value="1876">Jamaica (+1876)</Option>
        <Option data-countryCode="JP" value="81">Japan (+81)</Option>
        <Option data-countryCode="JO" value="962">Jordan (+962)</Option>
        <Option data-countryCode="KZ" value="7">Kazakhstan (+7)</Option>
        <Option data-countryCode="KE" value="254">Kenya (+254)</Option>
        <Option data-countryCode="KI" value="686">Kiribati (+686)</Option>
        <Option data-countryCode="KP" value="850">Korea North (+850)</Option>
        <Option data-countryCode="KR" value="82">Korea South (+82)</Option>
        <Option data-countryCode="KW" value="965">Kuwait (+965)</Option>
        <Option data-countryCode="KG" value="996">Kyrgyzstan (+996)</Option>
        <Option data-countryCode="LA" value="856">Laos (+856)</Option>
        <Option data-countryCode="LV" value="371">Latvia (+371)</Option>
        <Option data-countryCode="LB" value="961">Lebanon (+961)</Option>
        <Option data-countryCode="LS" value="266">Lesotho (+266)</Option>
        <Option data-countryCode="LR" value="231">Liberia (+231)</Option>
        <Option data-countryCode="LY" value="218">Libya (+218)</Option>
        <Option data-countryCode="LI" value="417">Liechtenstein (+417)</Option>
        <Option data-countryCode="LT" value="370">Lithuania (+370)</Option>
        <Option data-countryCode="LU" value="352">Luxembourg (+352)</Option>
        <Option data-countryCode="MO" value="853">Macao (+853)</Option>
        <Option data-countryCode="MK" value="389">Macedonia (+389)</Option>
        <Option data-countryCode="MG" value="261">Madagascar (+261)</Option>
        <Option data-countryCode="MW" value="265">Malawi (+265)</Option>
        <Option data-countryCode="MY" value="60">Malaysia (+60)</Option>
        <Option data-countryCode="MV" value="960">Maldives (+960)</Option>
        <Option data-countryCode="ML" value="223">Mali (+223)</Option>
        <Option data-countryCode="MT" value="356">Malta (+356)</Option>
        <Option data-countryCode="MH" value="692">Marshall Islands (+692)</Option>
        <Option data-countryCode="MQ" value="596">Martinique (+596)</Option>
        <Option data-countryCode="MR" value="222">Mauritania (+222)</Option>
        <Option data-countryCode="YT" value="269">Mayotte (+269)</Option>
        <Option data-countryCode="MX" value="52">Mexico (+52)</Option>
        <Option data-countryCode="FM" value="691">Micronesia (+691)</Option>
        <Option data-countryCode="MD" value="373">Moldova (+373)</Option>
        <Option data-countryCode="MC" value="377">Monaco (+377)</Option>
        <Option data-countryCode="MN" value="976">Mongolia (+976)</Option>
        <Option data-countryCode="MS" value="1664">Montserrat (+1664)</Option>
        <Option data-countryCode="MA" value="212">Morocco (+212)</Option>
        <Option data-countryCode="MZ" value="258">Mozambique (+258)</Option>
        <Option data-countryCode="MN" value="95">Myanmar (+95)</Option>
        <Option data-countryCode="NA" value="264">Namibia (+264)</Option>
        <Option data-countryCode="NR" value="674">Nauru (+674)</Option>
        <Option data-countryCode="NP" value="977">Nepal (+977)</Option>
        <Option data-countryCode="NL" value="31">Netherlands (+31)</Option>
        <Option data-countryCode="NC" value="687">New Caledonia (+687)</Option>
        <Option data-countryCode="NZ" value="64">New Zealand (+64)</Option>
        <Option data-countryCode="NI" value="505">Nicaragua (+505)</Option>
        <Option data-countryCode="NE" value="227">Niger (+227)</Option>
        <Option data-countryCode="NG" value="234">Nigeria (+234)</Option>
        <Option data-countryCode="NU" value="683">Niue (+683)</Option>
        <Option data-countryCode="NF" value="672">Norfolk Islands (+672)</Option>
        <Option data-countryCode="NP" value="670">Northern Marianas (+670)</Option>
        <Option data-countryCode="NO" value="47">Norway (+47)</Option>
        <Option data-countryCode="OM" value="968">Oman (+968)</Option>
        <Option data-countryCode="PW" value="680">Palau (+680)</Option>
        <Option data-countryCode="PA" value="507">Panama (+507)</Option>
        <Option data-countryCode="PG" value="675">Papua New Guinea (+675)</Option>
        <Option data-countryCode="PY" value="595">Paraguay (+595)</Option>
        <Option data-countryCode="PE" value="51">Peru (+51)</Option>
        <Option data-countryCode="PH" value="63">Philippines (+63)</Option>
        <Option data-countryCode="PL" value="48">Poland (+48)</Option>
        <Option data-countryCode="PT" value="351">Portugal (+351)</Option>
        <Option data-countryCode="PR" value="1787">Puerto Rico (+1787)</Option>
        <Option data-countryCode="QA" value="974">Qatar (+974)</Option>
        <Option data-countryCode="RE" value="262">Reunion (+262)</Option>
        <Option data-countryCode="RO" value="40">Romania (+40)</Option>
        <Option data-countryCode="RU" value="7">Russia (+7)</Option>
        <Option data-countryCode="RW" value="250">Rwanda (+250)</Option>
        <Option data-countryCode="SM" value="378">San Marino (+378)</Option>
        <Option data-countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</Option>
        <Option data-countryCode="SA" value="966">Saudi Arabia (+966)</Option>
        <Option data-countryCode="SN" value="221">Senegal (+221)</Option>
        <Option data-countryCode="CS" value="381">Serbia (+381)</Option>
        <Option data-countryCode="SC" value="248">Seychelles (+248)</Option>
        <Option data-countryCode="SL" value="232">Sierra Leone (+232)</Option>
        <Option data-countryCode="SG" value="65">Singapore (+65)</Option>
        <Option data-countryCode="SK" value="421">Slovak Republic (+421)</Option>
        <Option data-countryCode="SI" value="386">Slovenia (+386)</Option>
        <Option data-countryCode="SB" value="677">Solomon Islands (+677)</Option>
        <Option data-countryCode="SO" value="252">Somalia (+252)</Option>
        <Option data-countryCode="ZA" value="27">South Africa (+27)</Option>
        <Option data-countryCode="ES" value="34">Spain (+34)</Option>
        <Option data-countryCode="LK" value="94">Sri Lanka (+94)</Option>
        <Option data-countryCode="SH" value="290">St. Helena (+290)</Option>
        <Option data-countryCode="KN" value="1869">St. Kitts (+1869)</Option>
        <Option data-countryCode="SC" value="1758">St. Lucia (+1758)</Option>
        <Option data-countryCode="SD" value="249">Sudan (+249)</Option>
        <Option data-countryCode="SR" value="597">Suriname (+597)</Option>
        <Option data-countryCode="SZ" value="268">Swaziland (+268)</Option>
        <Option data-countryCode="SE" value="46">Sweden (+46)</Option>
        <Option data-countryCode="CH" value="41">Switzerland (+41)</Option>
        <Option data-countryCode="SI" value="963">Syria (+963)</Option>
        <Option data-countryCode="TW" value="886">Taiwan (+886)</Option>
        <Option data-countryCode="TJ" value="7">Tajikstan (+7)</Option>
        <Option data-countryCode="TH" value="66">Thailand (+66)</Option>
        <Option data-countryCode="TG" value="228">Togo (+228)</Option>
        <Option data-countryCode="TO" value="676">Tonga (+676)</Option>
        <Option data-countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</Option>
        <Option data-countryCode="TN" value="216">Tunisia (+216)</Option>
        <Option data-countryCode="TR" value="90">Turkey (+90)</Option>
        <Option data-countryCode="TM" value="7">Turkmenistan (+7)</Option>
        <Option data-countryCode="TM" value="993">Turkmenistan (+993)</Option>
        <Option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</Option>
        <Option data-countryCode="TV" value="688">Tuvalu (+688)</Option>
        <Option data-countryCode="UG" value="256">Uganda (+256)</Option>
        <Option data-countryCode="GB" value="44">UK (+44)</Option> 
        <Option data-countryCode="UA" value="380">Ukraine (+380)</Option>
        <Option data-countryCode="AE" value="971">United Arab Emirates (+971)</Option>
        <Option data-countryCode="UY" value="598">Uruguay (+598)</Option>
        
        <Option data-countryCode="UZ" value="7">Uzbekistan (+7)</Option>
        <Option data-countryCode="VU" value="678">Vanuatu (+678)</Option>
        <Option data-countryCode="VA" value="379">Vatican City (+379)</Option>
        <Option data-countryCode="VE" value="58">Venezuela (+58)</Option>
        <Option data-countryCode="VN" value="84">Vietnam (+84)</Option>
        <Option data-countryCode="VG" value="84">Virgin Islands - British (+1284)</Option>
        <Option data-countryCode="VI" value="84">Virgin Islands - US (+1340)</Option>
        <Option data-countryCode="WF" value="681">Wallis &amp; Futuna (+681)</Option>
        <Option data-countryCode="YE" value="969">Yemen (North)(+969)</Option>
        <Option data-countryCode="YE" value="967">Yemen (South)(+967)</Option>
        <Option data-countryCode="ZM" value="260">Zambia (+260)</Option>
        <Option data-countryCode="ZW" value="263">Zimbabwe (+263)</Option>
      </Select>
    );
    return (
      <div style={{display:this.state.show_data}}>
        <WebHeader />
        <section className="signup-section" style={{marginTop:30}}>
          <Fetchurldata setEmail={this.setCode} />
          <div className="container">
            <div className="row">
              <div className="col-lg-5 m-auto">
                <Spin spinning={this.state.loading}>
                  <div className="login-form">
                    <h3 className="text-center">Get Started</h3>
                    <div className='or-div'>
                      <hr/>
                    </div>

                    {this.state.screen1==true && (
                      <>
                        <div className="row">
                          <div className="col-lg-12">
                            <Radio.Group
                              className="typeofmember-radio" 
                              onChange={this.onChangeMembertype} 
                              value={this.state.typeofmember}
                              style={{marginBottom:25,marginLeft:'12%'}}
                            >
                              <Radio value={'Indian Resident'}>Indian Resident</Radio>
                              <Radio value={'Non Resident'}>Non Resident</Radio>
                            </Radio.Group>
                          </div>
                          <div className="col-lg-6">
                            <label>First Name <span className='text-danger'>*</span></label>
                            <input 
                              type="text" 
                              name="first_name" 
                              className="form-input-field" 
                              value={this.state.first_name} 
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label>Middle Name (Optional)</label>
                            <input 
                              type="text" 
                              name="middle_name" 
                              className="form-input-field" 
                              value={this.state.middle_name} 
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="col-lg-12">
                            <label>Last Name <span className='text-danger'>*</span></label>
                            <input 
                              type="text" 
                              name="last_name" 
                              className="form-input-field" 
                              value={this.state.last_name} 
                              onChange={this.handleChange}
                            />
                          </div>
                          {/* <div className="col-lg-12">
                            <label>Email <span className='text-danger'>*</span></label>
                            <input 
                              type="text" 
                              name="email" 
                              className="form-input-field" 
                              value={this.state.email} 
                              onChange={this.handleChange}
                            />
                          </div> */}
                          <div className="col-lg-12">
                            {this.state.typeofmember=='Non Resident' ? (
                              <div style={{position:'relative'}} className='international-mobile-field'>
                                <label>Mobile No <span className='text-danger'>*</span></label>
                                <Input 
                                showSearch
                                  addonBefore={selectBefore}
                                  defaultValue="mysite" 
                                  name="phone" 
                                  minLength={7}
                                  maxLength={15}
                                  optionFilterProp="children"
                                  onSearch={this.onSearch}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  // className="form-input-field" 
                                  value={this.state.phone.trim()} 
                                  onChange={(e)=>this.setState({phone:e.target.value})}
                                />
                                <div className='mt-4'>
                                  <label> Indian Contact Number(Optional)</label>
                              </div>
                                <Input 
                                showSearch
                                  // addonBefore={selectBefore}
                                  // defaultValue="mysite" 
                                  name="phone1" 
                                  minLength={10}
                                  maxLength={10}
                                  // optionFilterProp="children"
                                  // onSearch={this.onSearch}
                                  // filterOption={(input, option) =>
                                  //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  // }
                                  // className="form-input-field" 
                                  value={this.state.phone1} 
                                  onChange={(e)=>this.setState({phone1:e.target.value})}
                                />
                              </div>
                            ):(
                              <>
                                {this.state.otpsent==true ? (
                              <div style={{position:'relative'}}>
                                
                                {this.state.verfied==true? (<>
                                  <label>Mobile No <span className='text-danger'>*</span></label>
                                  <input
                                    type="tel" 
                                    name="phone" 
                                    minLength={10}
                                    maxLength={10}
                                    pattern="[0-9]{10}"
                                    className="form-input-field" 
                                    value={this.state.phone.trim()} 
                                    disabled={true}
                                  />
                                  </>
                                ):(<>
                                  <label>Mobile No <span className='text-danger'>*</span></label>
                                  <input 
                                    type="tel" 
                                    name="phone"
                                    minLength={10}
                                    maxLength={10}
                                    pattern="[0-9]{10}" 
                                    className="form-input-field" 
                                    value={this.state.phone.trim()} 
                                    onChange={(e)=>{this.setState({phone:e.target.value})}}
                                    readOnly={this.state.istimerstarted2}
                                  />
                                   <label>OTP<span className='text-danger'>*</span></label>
                                   <div className='input-group'>
                                  <input 
                                    type="tel" 
                                    name="mobile_no_otp" 
                                    className="form-input-field" 
                                    placeholder='Please enter OTP'
                                    pattern={'0-9'}
                                    value={this.state.mobile_no_otp} 
                                    onChange={(e)=>this.setState({mobile_no_otp:e.target.value})}
                                  />
                                   {this.state.verfied==false && (
                                  <button 
                                    style={{right:76,background:this.state.verfied==true ? 'green' : '#29176f',borderRadius:5}}
                                    className='send_otp_button_op'
                                    onClick={this.verify_mobile_otp}
                                  >Verify</button>
                                )}
                                {this.state.verfied==false && (
                                   <button 
                                   style={{
                                     background: this.state.istimerstarted2==true?'#a29f9f':'#29176f',
                                     border: this.state.istimerstarted2==true?'1px solid #a29f9f':'#29176f',
                                     borderRadius:5
                                   }}  
                                   className='send_otp_button_op'
                                   disabled={this.state.istimerstarted2==true?true:false}
                                   onClick={this.resent_mobile_otp}
                                 >Resend</button>
                                )}<div style={{position:'relative'}}>
                                
                                {(this.state.istimerstarted2==true && this.state.verfied==false) &&(
                                  <div style={{position: 'absolute',right: 0,top: 44}}>
                                    {this.state.mobileminuts}:{this.state.mobileseconds < 10 ? `0${this.state.mobileseconds}` : this.state.mobileseconds}
                                  </div>
                                )}
                              </div>
                                  </div></>
                                )}
                                
                                {this.state.verfied==true && (
                                  <span className='text-success' style={{position:'relative',top:-19}}>Mobile Number Verified successfully.</span>
                                )}
                               
                              </div>
                            ):(
                              <div style={{position:'relative'}}>
                                <label>Mobile No <span className='text-danger'>*</span></label>
                                <input 
                                  type="tel" 
                                  name="phone" 
                                  
                                  minLength={10}
                                  maxLength={10}
                                  pattern="[0-9]{10}"
                                  className="form-input-field" 
                                  value={this.state.phone.trim()} 
                                  onChange={this.handleChangePhoneNo}
                                />
                                <button 
                                className='send_otp_button'
                                onClick={this.sendotptomobile}
                                >Send OTP</button>
                              </div>
                            )}
                              </>
                            )}
                          </div>

                            {/* Email OTP */}
                              {/* Email Send OTP */}
                              {this.state.email_otpsent==true ? (
                              <div style={{position:'relative'}}>
                                
                                {this.state.email_verified==true? (
                                      <>
                                        <label>Email <span className='text-danger'>*</span></label>
                                        <input 
                                          
                                          type="tel"
                                          name="num1"
                                          className="form-input-field"
                                          onChange={(e) => this.onChangeNum1(e)}
                                          value={this.state.email.trim()}
                                          disabled={true}
                                        />
                                        </>
                                      ):(<>
                                        <label>Email <span className='text-danger'>*</span></label>
                                        <input 
                                          type="tel"
                                          name="num1"
                                          className="form-input-field"
                                          // onChange={(e) => this.onChangeNum1(e)}
                                          value={this.state.email.trim()}
                                          onChange={(e)=>{this.setState({num1:e.target.value})}}
                                          readOnly={this.state.istimerstarted}
                                        />
                                          <label>OTP<span className='text-danger'>*</span></label>
                                          <div className='input-group'>
                                        <input 
                                          type="text" 
                                          name="num1" 
                                          className="form-input-field" 
                                          placeholder='Please enter OTP'
                                          value={this.state.num1} 
                                          onChange={(e)=>this.setState({num1:e.target.value})}
                                        />
                                          {this.state.email_verified==false && (
                                        <button 
                                          style={{right:76,background:this.state.email_verified==true ? 'green' : '#29176f',borderRadius:5}}
                                          className='send_otp_button_op'
                                          onClick={this.verify_mail_otp}
                                        >Verify</button>
                                      )}
                                      {this.state.email_verified==false && (
                                          <button 
                                          style={{
                                            background: this.state.istimerstarted==true?'#a29f9f':'#29176f',
                                            border: this.state.istimerstarted==true?'1px solid #a29f9f':'#29176f',
                                            borderRadius:5
                                          }}  
                                          className='send_otp_button_op'
                                          disabled={this.state.istimerstarted==true?true:false}
                                          onClick={this.resendotp}
                                        >Resend</button>
                                      )}
                                      <div style={{position:'relative'}}>                                      
                                        {(this.state.istimerstarted==true && this.state.email_verified==false) &&(
                                          <div style={{position:'absolute',right:-23,top:-29}}>
                                            <div className='otp-timer'>
                                              <CountdownTimer 
                                                timeLeft={this.state.istimerstarted==true ? Number(this.state.countemailseconds):0} 
                                                completeCallback={this.emailcompleted} 
                                                tickCallback={this.emailtick} 
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                  </div>
                                  </>
                                )}                                
                                {this.state.email_verified==true && (
                                  <span className='text-success' style={{position:'relative',top:-19}}>Email is Verified successfully.</span>
                                )}
                              </div>
                            ):(
                          <div className="col-lg-12">
                            <div style={{position:'relative'}}>
                              <label>Email <span className='text-danger'>*</span></label>
                              <input 
                                type="text" 
                                name="email" 
                                className="form-input-field" 
                                value={this.state.email.trim()} 
                                onChange={this.handleChange}
                              />
                              <button 
                              className='send_otp_button'
                              onClick={this.sendotptomail}
                              >Send OTP</button>
                            </div>
                            </div>)
                          }
                            

                          <div className="col-lg-12">
                            <label>Referral Code</label>
                            <input 
                              type="text" 
                              name="refferal_code" 
                              className="form-input-field" 
                              value={this.state.refferal_code} 
                              onChange={(e)=>this.setState({refferal_code:e.target.value},()=>this.check_refferal_code())}
                            />
                            {(this.state.is_refferal_code_matched==false && this.state.show_error==true && this.state.refferal_code!='') && (
                               <span className="text-danger" style={{ top: -12,fontSize: 16,position: 'relative' }}>Invalid Referral Code</span>
                            )}
                            {(this.state.is_refferal_code_matched==true && this.state.show_error==true && this.state.refferal_code!='') && (
                               <span className="text-success" style={{ top: -12,fontSize: 16,position: 'relative' }}>Referral Code is matched.</span>
                            )}
                          </div>
                          <br/>
                        </div>
                        <button 
                        style={{marginTop:20}}
                        onClick={this.register} type="button" className="login-button">Sign up</button>
                      </>
                    )}
                    {/* {this.state.otpscreen==true &&(
                      <div className="login-form">
                      <h3 className="text-center">Login</h3>
                      <p
                        style={{
                          fontSize:16,
                          color:"#000",
                          paddingBottom:33,
                          lineHeight:'24px',
                        }}
                      >
                        OTP is sent to your Email. Please enter it below:
                      </p>
                      <div
                      style={{
                        position:'absolute',
                        right:33,
                        marginTop:-25,
                        display:'flex',
                      }}
                      >
                        {this.state.istimerstarted==true ? (
                          <span className="resend-otp-link" style={{cursor:'not-allowed'}}>
                            <span style={{color:'#a3a0a0'}}>Resend OTP</span>
                          </span> 
                        ):(
                          <span className="resend-otp-link" onClick={this.resendotp}>
                            <span >Resend OTP</span>
                          </span> 
                        )}
                        {this.state.istimerstarted==true&& (                      
                          <span style={{marginLeft:10}}>
                            ({this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds})
                          </span>
                        )}     
                      </div>
                      <div className="otp-input">
                        <input
                          type="text"
                          name="num1"
                          className="form-input-field"
                          onChange={(e) => this.onChangeNum1(e)}
                          value={this.state.num1}
                        />
                      </div>
                      <button
                        type="button"
                        className="login-button"
                        onClick={this.verifyotp}
                      >
                        Verify
                      </button>
                    </div>
                    )} */}


                    {/* {this.state.screen2==true && (
                      <>
                        <div className="row">
                          <div className="col-lg-12">
                            <select 
                              name="nationality" 
                              className="form-input-field"
                              value={this.state.nationality} 
                              onChange={this.handleChangeSelect} 
                            >
                              <option value=''>Select Nationality</option>
                              <option value='Indian Resident'>Indian Resident</option>
                              <option value='NRI Having NRO Account'>NRI Having NRO Account</option>
                              <option value='Other'>Other</option>
                            </select>
                          </div>
                        </div>
                        <button onClick={this.register} type="button" className="login-button">Next</button>
                      </>
                    )} */}
                    
                    <hr style={{ 
                      border: '1px solid rgb(170 167 167)',
                      background: '#ddd',
                      margin: '33px 0',
                    }}/>
                    <div className="d-flex">
                      <span>Already have an account?</span> &nbsp;&nbsp;
                      <a href="/Login">Log in instead</a>
                    </div>
                    <div className="d-flex">
                      
                      <a href="/founder-registration">Click Here</a>
                      &nbsp;&nbsp;<span>to apply as Founder</span>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </section>
        <WebFooter />
      </div>
    )
  }
}

export default Step1;
